import apiClient from "@/services/client";
import { DateTime } from "luxon";

export enum AuditLogReferenceType {
  Url = "url",
  Assignment = "assignment",
  Status = "status",
}

export enum AuditLogEventType {
  Redirected = "Redirected",
  PageViewed = "PageViewed",
  LessonActivityStarted = "LessonActivityStarted",
  LessonStarted = "LessonStarted",
  LessonCompleted = "LessonCompleted",
  UserLoggedIn = "UserLoggedIn",
  UserLoggedOut = "UserLoggedOut",
  UserActionItem = "UserActionItem",
  AccordionOpened = "AccordionOpened",
  AccordionClosed = "AccordionClosed",
  EventPublished = "EventPublished",
  EventDue = "EventDue",
  Milestone = "Milestone",
  CohortLaunch = "CohortLaunch",
  CohortEnd = "CohortEnd",
}

export enum AuditLogEventActor {
  Cohort = "cohort",
  User = "user",
}

interface APIAuditLogReference {
  type: AuditLogReferenceType;
  content: string | number;
}

interface APIAuditLogEvent {
  type: AuditLogEventType | string;
  timestamp: string;
  note: string | null;
  title: string;
  references: APIAuditLogReference[] | null;
  actor: AuditLogEventActor;
}

interface APIAuditLog {
  username: string;
  name: string;
  events: APIAuditLogEvent[];
  references: APIAuditLogReference[];
}

export interface AuditLogReference extends APIAuditLogReference {}

export interface AuditLogEvent
  extends Omit<APIAuditLogEvent, "timestamp" | "references"> {
  timestamp: DateTime;
  references: AuditLogReference[] | null;
}

export interface AuditLog {
  username: string;
  name: string;
  events: AuditLogEvent[];
  references: APIAuditLogReference[];
}

const toAuditLogEvent = (event: APIAuditLogEvent): AuditLogEvent => ({
  ...event,
  timestamp: DateTime.fromISO(event.timestamp),
});

const toAuditLog = (auditLog: APIAuditLog): AuditLog => ({
  ...auditLog,
  events: auditLog.events.map(toAuditLogEvent),
});

export async function getAuditLog(
  cohortId: string,
  memberId: string
): Promise<AuditLog> {
  const { data } = await apiClient.get<APIAuditLog>(
    `/api/cohorts/${cohortId}/activity-logs/${memberId}`
  );
  return toAuditLog(data);
}
