
import { PropType, defineComponent } from "vue";
import { DateTime } from "luxon";
import PlannerEventCard from "@/components/PlannerEventCard.vue";
import TandemDateInput from "@/components/TandemDateInput.vue";
import TandemSelect from "@/components/TandemSelect.vue";
import {
  CalendarEvent,
  EventUpdateData,
  Assignment,
  EventTypeCategory,
} from "@/core";

export default defineComponent({
  props: {
    availableAssignments: {
      type: Array as PropType<Assignment[]>,
      required: true,
    },
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  emits: ["remove", "submit"],
  components: {
    TandemDateInput,
    TandemSelect,
    PlannerEventCard,
  },
  data() {
    const { publishDate, dueDate } = this.calendarEvent.extendedProps.element;
    return {
      publishDate,
      dueDate,
      selectedAssignment: this.assignment,
    };
  },
  computed: {
    formValid() {
      return (
        this.publishDate && this.dueDate && this.publishDate < this.dueDate
      );
    },
    assignmentOptions(): Assignment[] {
      return this.availableAssignments
        .filter((assignment) => assignment.id !== this.assignment.id)
        .filter((assignment) => assignment.type === this.assignment.type)
        .concat(this.assignment);
    },
    choicesAreAvailable(): boolean {
      return this.assignmentOptions.length > 1;
    },
  },
  methods: {
    handleSubmit(event: Event) {
      event.preventDefault();
      const eventData: EventUpdateData = {
        publishDate: this.publishDate,
        dueDate: this.dueDate,
        type: this.selectedAssignment,
      };
      this.$emit("submit", eventData);
    },
    getAssignmentLabel(assignment: Assignment) {
      return assignment.type === EventTypeCategory.Lesson &&
        !assignment.isSystemAssigned
        ? `${assignment.name} Lesson`
        : assignment.name;
    },
    getAssignmentKey(assignment: Assignment) {
      return assignment.id;
    },
  },
  watch: {
    publishDate(newDate: DateTime, prevDate: DateTime) {
      const pubDueDifference = this.dueDate.diff(prevDate).as("days");
      this.dueDate = newDate.plus({ days: pubDueDifference });
    },
  },
});
