import { loadPreviousOrCloseModal } from "@/scripts/faculty/modal-actions.js";

const form = "#assignmentActionsForm";
const submitButton = `${form} [type="submit"]`;
const confirmationSection = "#assignment-confirmation";
const confirmationInput = "#id_confirmation";
const surveyInputs = "form input[name!='confirmation']";
const checkedSurveyInputs = "form input:checked[name!='confirmation']";

const getFormData = () => $(form).serialize();
const getPostUrl = () => $(form).data("post-url");
const getIndividualName = () => $(form).data("username");
const show = (selector) => $(selector).removeClass("hidden");
const hide = (selector) => $(selector).addClass("hidden");

const disableSubmit = (disable = true) =>
  $(submitButton).prop("disabled", disable);

const displayConfirmation = (showConfirmation = true) => {
  if (showConfirmation) {
    show(confirmationSection);
  } else {
    hide(confirmationSection);
  }
};

const isConfirmationHidden = () => $(confirmationSection).hasClass("hidden");

const isConfirmationChecked = () => $(confirmationInput).is(":checked");

const isAtLeastOneSurveySelected = () => $(checkedSurveyInputs).length > 0;

const successAlertMessage = (name, surveys) => {
  if (surveys.length > 1) {
    return `${surveys.length} assignments updated for ${name}.`;
  } else if (surveys.length === 1) {
    return `${surveys[0]} updated for ${name}.`;
  }
};

const getSelectedSurveyLabels = () =>
  $(checkedSurveyInputs).map(function () {
    return $(this).parent().text().trim();
  });

const createSuccessAlertMessage = () => {
  const name = getIndividualName();
  const surveys = getSelectedSurveyLabels();
  return successAlertMessage(name, surveys);
};

const submitForm = () => {
  const formData = getFormData();
  const url = getPostUrl();
  const successMessage = createSuccessAlertMessage();
  $.post(url, formData, () => {
    loadPreviousOrCloseModal();
    alert(successMessage);
  });
};

const submitShouldBeEnabled = () => {
  const confirmationHiddenOrChecked =
    isConfirmationHidden() || isConfirmationChecked();
  const surveySelected = isAtLeastOneSurveySelected();
  return confirmationHiddenOrChecked && surveySelected;
};

const updateSubmitButtonStatus = () => {
  const disable = !submitShouldBeEnabled();
  disableSubmit(disable);
};

const handleFormSubmit = (event) => {
  event.preventDefault();
  if (isConfirmationHidden()) {
    disableSubmit();
    displayConfirmation();
  } else {
    submitForm();
  }
};

export function initializeForm() {
  $(submitButton).on("click", handleFormSubmit);
  $(confirmationInput).on("change", updateSubmitButtonStatus);
  $(surveyInputs).on("change", updateSubmitButtonStatus);
}

$(document).ready(initializeForm);
