export function getGraphData(url) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: url,
      type: "GET",
      success: function (data) {
        resolve(data);
      },
      error: function (error) {
        reject(error);
      },
    });
  });
}

export function getMidtermGraphLayout() {
  var layout = {
    barmode: "group",
    bargap: 0.85,
    hovermode: false,
    paper_bgcolor: "transparent",
    showlegend: false,
    height: 300,
    margin: {
      l: 40,
      r: 0,
      t: 0,
      b: 15,
      pad: 0,
    },
    xaxis: {
      fixedrange: true,
      showgrid: false,
    },
    yaxis: {
      fixedrange: true,
      showgrid: false,
      showticklabels: false,
      zeroline: false,
      gridwidth: 1,
      range: [0, 10],
    },
  };

  return layout;
}

export function getSingleBarLayout() {
  let layout = getMidtermGraphLayout();
  layout.barmode = null;
  layout.bargap = null;
  return layout;
}

export function getMidtermGraphBars() {
  var myBar = {
    name: "YOU",
    type: "bar",
    base: 0.025,
    offset: -0.225,
    width: 0.18,
    marker: {
      symbol: "circle",
      size: 18,
      color: "#0079ba",
    },
  };

  var teamBar = {
    name: "TEAM AVERAGE",
    type: "bar",
    base: 0.025,
    offset: 0.06,
    width: 0.18,
    marker: {
      color: "#f1e926",
    },
  };

  return [myBar, teamBar];
}

export function getMidtermOverallGraphLayout() {
  var layout = {
    barmode: "group",
    bargap: 0.85,
    hovermode: false,
    paper_bgcolor: "transparent",
    showlegend: false,
    height: 330,
    margin: {
      l: 0,
      r: 0,
      t: 0,
      b: 25,
      pad: 5,
    },

    xaxis: {
      fixedrange: true,
      showgrid: false,
    },
    yaxis: {
      fixedrange: true,
      showgrid: false,
      showticklabels: false,
      zeroline: false,
      gridwidth: 1,
    },
  };

  return layout;
}

export function getMidtermOverallGraphBar() {
  return {
    name: "OVERALL",
    type: "bar",
    base: 0,
    offset: -0.14,
    width: 0.28,
    marker: {
      color: ["#941a25", "#f8b7bb", "#0079ba", "#f1e926", "#003032"],
    },
  };
}
