export function initializeSurveyScripts() {
  // TRIGGER SAVE RATHER THAN DELETE
  $("form").on("keypress", function (e) {
    var ENTER_KEY_CODE = 13;
    var deleteButtonPressed = e.target.name === "delete";

    if (e.which === ENTER_KEY_CODE && !deleteButtonPressed) {
      e.preventDefault();
      $("#submit").trigger("click");
    }
  });

  $("input:checked")
    .add("input[checked=checked]")
    .each(function () {
      $(this).parents("li").addClass("checked");
      $(this).parent("label").parent("div").addClass("checked");
    });
  $("input[type=radio]").on("click", toggleRadioSelected);
  $("input[type=checkbox]").on("click", toggleCheckboxSelected);

  moveOtherToBottom();
  sliderWithAnchors();
  createMatrix();
}

export function toggleRadioSelected() {
  var me = $(this);
  var inputName = me.attr("name");

  let input;
  try {
    input = $("input[type=radio][name=" + inputName + "]");
  } catch (e) {
    input = $("input[type=radio]");
  }

  if (me.parents("li").length) {
    $(input).parents("li").removeClass("checked");
    me.parents("li").addClass("checked");
  } else if (me.parent("label").parent("div").length) {
    $(input).parent("label").parent("div").removeClass("checked");
    me.parent("label").parent("div").addClass("checked");
  } else {
    $(input).parents("td").removeClass("checked");
    me.parents("td").addClass("checked");
  }
}

function toggleCheckboxSelected() {
  let me = $(this);
  let container = "";

  if (me.parents("li").length) {
    container = me.parents("li");
  } else {
    container = me.parent("label").parent("div");
  }

  if (me.prop("checked")) {
    container.addClass("checked");
  } else {
    container.removeClass("checked");
  }
}

function moveOtherToBottom() {
  var otherInputs = $("[data-other-for]");

  $(otherInputs).each(function () {
    var me = $(this);
    var question = me.closest(".survey_question");
    var response = question.find("li");
    var thisResponse = me.closest("li");

    if (question.find("input[type=text]").length < 1) {
      return;
    }

    if (
      response.index(thisResponse) != response.length - 1 &&
      me.attr("data-other-value") == "Other"
    ) {
      thisResponse.parent("ul").append(thisResponse[0].outerHTML);
      thisResponse.remove();
      me = question.find("[data-other-for]");
    }

    $("input[type=checkbox]").on("click", toggleCheckboxSelected);
    thisResponse = me.closest("li").addClass("other-option");
    thisResponse.on("click", function () {
      me.trigger("focus");
    });

    me.on("keyup", function () {
      var hasTextInput = me.val().length > 0;

      if (hasTextInput && !thisResponse.hasClass("checked")) {
        thisResponse.addClass("checked");
        thisResponse
          .find("input[type=checkbox],input[type=radio]")
          .prop("checked", true);
      } else if (!hasTextInput) {
        thisResponse.removeClass("checked");
        thisResponse
          .find("input[type=checkbox],input[type=radio]")
          .prop("checked", false);
      }
    });
  });
}

export function sliderWithAnchors() {
  var sliderQuestion = $(".slider-anchors");
  var select = sliderQuestion.find("select");

  select.each(function (i, thisSelect) {
    var thisQuestion = $(thisSelect).parents(".slider-anchors");
    var options = $(thisSelect).children("option");
    var selectLength = options.length - 1;
    var firstOption = $(options)[1];
    var lastOption = $(options)[selectLength];
    var selectMin = $(firstOption).val();
    var selectMax = $(lastOption).val();
    var midNum = Math.ceil(selectMax / 2);
    var leftAnchorText = $(firstOption).text();
    var rightAnchorText = $(lastOption).text();
    var anchors = `
            <div class="anchor-container">
                <div class="anchor anchor-left">${leftAnchorText}</div>
                <div class="anchor anchor-right">${rightAnchorText}</div>
            </div>
        `;

    var hasError = thisQuestion.find(".survey_error");
    if (hasError.length > 0) {
      hasError.text(
        `Please provide an answer to this question. You must interact with the scale. If you’d like to select “${midNum}”, please click the slider to make your selection.`
      );
    }

    if ($(thisSelect).val() == -1) {
      thisQuestion.addClass("no-interaction");
    } else {
      thisQuestion.removeClass("no-interaction");
    }
    if (!$(thisSelect).hasClass("anchor-created")) {
      $(thisSelect).addClass("anchor-created");

      var slider = $('<div class="slider"></div>')
        .insertAfter(thisSelect)
        .slider({
          animate: true,
          min: Number(selectMin),
          max: Number(selectMax),
          value: $(thisSelect)[0].selectedIndex,
          create: function () {
            if ($(thisSelect).val() == -1) {
              $(this).slider("value", midNum);
            }
          },
          slide: function (event, ui) {
            if (selectMin == 0) {
              $(thisSelect)[0].selectedIndex = ui.value + 1;
            } else {
              $(thisSelect)[0].selectedIndex = ui.value;
            }

            if ($(thisSelect).val() !== -1) {
              thisQuestion.removeClass("no-interaction");
            }
          },
        });

      slider.prepend('<div class="hash-container"></div>');
      slider.prepend('<div class="number-container"></div>');
      for (let index = selectMin; index <= selectMax; index++) {
        var hashContainer = $(slider).find(".hash-container");
        var numberContainer = $(slider).find(".number-container");

        hashContainer.append('<div class="hash-mark"></div>');
        numberContainer.append(`<div class="number">${index}</div>`);
      }

      $(anchors).insertAfter(slider);
    }
  });
  $(".ui-slider-handle").on("click", sliderDefaultToSelected);
}

function sliderDefaultToSelected() {
  var handle = $(this);
  var thisSlider = handle.parents(".slider");
  var thisQuestion = handle.parents(".slider-anchors");
  var thisSelect = thisQuestion.find("select");
  var options = $(thisSelect).children("option");
  var selectLength = options.length - 1;
  var selectMax = $($(options)[selectLength]).val();
  var midNum = Math.ceil(selectMax / 2);

  if (
    thisQuestion.hasClass("no-interaction") &&
    thisSlider.slider("value") === midNum
  ) {
    thisQuestion.removeClass("no-interaction");
    thisSelect.val(midNum);
  }
}

function createMatrix() {
  let matrixCollection = $(".matrix-group");

  matrixCollection.each(function (i, matrix) {
    let labelContainer = $($(matrix).find(".survey_responses")[0]).find("li");
    let matrixQuestions = $(matrix).find(".survey_question");

    matrixQuestions.each(function (i, question) {
      $(question).wrapInner(`<div class="question-wrapper"></div>`);
    });

    $(matrix).prepend(`
      <div class="matrix-labels">
          <div class="empty"></div>
          <div class="label-block"></div>
      </div>
    `);

    labelContainer.each(function (i, container) {
      var label = $(container).find("label").text();

      $(matrix)
        .find(".label-block")
        .append(`<div class="label-item">${label}</div>`);
    });
  });
}
