const padZeroes = (number) => number.toString().padStart(2, "0");

export const formatDate = (date) => {
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear().toString().slice(-2);
  const hour24 = date.getHours();
  const hour12 = hour24 % 12;
  const hour = hour12 > 0 ? hour12 : 12;
  const minutes = padZeroes(date.getMinutes());
  const ampm = hour24 >= 12 ? "PM" : "AM";
  return `${hour}:${minutes}${ampm} - ${month}.${day}.${year}`;
};

export const addDaysToDate = (date, days) => {
  const extendedDate = new Date(date);
  extendedDate.setDate(extendedDate.getDate() + days);
  return extendedDate;
};
