import { DateTime } from "luxon";
import apiClient from "@/services/client";

interface APICohortDescription {
  id: number;
  name: string;
  full_name: string;
}
interface APIActionItemCollection {
  id: number;
  title: string;
  publish_date: string;
  due_date: string;
  cohort: APICohortDescription;
  action_type: string;
}
interface APIActionItem {
  id: number;
  cohort: APICohortDescription;
  title: string;
  content_type_title: string;
  publish_date: string;
  due_date: string;
}

export interface ActionItemCollection {
  id: number;
  publishDate: DateTime;
  dueDate: DateTime;
  title: string;
  cohort: string;
  actionType: string;
}
export interface ActionItem {
  id: number;
  cohort: string;
  title: string;
  contentTypeTitle: string;
  publishDate: DateTime;
  dueDate: DateTime;
}

export interface Assignment {
  id: number;
  title: string;
  cohort: string;
  publishDate: DateTime;
  dueDate: DateTime;
  assignmentType: AssignmentType;
}

function toActionItemCollection(
  apiCollection: APIActionItemCollection
): ActionItemCollection {
  return {
    id: apiCollection.id,
    publishDate: DateTime.fromISO(apiCollection.publish_date),
    dueDate: DateTime.fromISO(apiCollection.due_date),
    title: apiCollection.title,
    cohort: apiCollection.cohort.full_name,
    actionType: apiCollection.action_type,
  };
}
function toActionItem(apiActionItem: APIActionItem): ActionItem {
  return {
    id: apiActionItem.id,
    title: apiActionItem.title,
    cohort: apiActionItem.cohort.full_name,
    contentTypeTitle: apiActionItem.content_type_title,
    publishDate: DateTime.fromISO(apiActionItem.publish_date),
    dueDate: DateTime.fromISO(apiActionItem.due_date),
  };
}

export async function getActionItemCollections(): Promise<
  ActionItemCollection[]
> {
  const { data } = await apiClient.get<APIActionItemCollection[]>(
    "/api/action-item-collections"
  );
  const formattedData = data.map(toActionItemCollection);
  return formattedData;
}

export async function getActionItems(): Promise<ActionItem[]> {
  const { data } = await apiClient.get<APIActionItem[]>("/api/action-items");
  const formattedData = data.map(toActionItem);
  return formattedData;
}

export function actionItemCollectionToAssignment(
  collection: ActionItemCollection
): Assignment {
  return {
    id: collection.id,
    publishDate: collection.publishDate,
    dueDate: collection.dueDate,
    cohort: collection.cohort,
    title: collection.title,
    assignmentType: getCollectionAssignmentType(collection),
  };
}
export function actionItemToAssignment(item: ActionItem): Assignment {
  return {
    id: item.id,
    publishDate: item.publishDate,
    dueDate: item.dueDate,
    title: item.title,
    cohort: item.cohort,
    assignmentType: getActionItemAssignmentType(item),
  };
}

type AssignmentType =
  | "Team Check"
  | "Automated Lesson"
  | "Team Evaluation"
  | "Lesson"
  | "One-Time Survey"
  | "Unknown";

function getCollectionAssignmentType(
  collection: ActionItemCollection
): AssignmentType {
  if (collection.actionType === "teamcheck") {
    return "Team Check";
  } else if (collection.actionType === "content") {
    return "Automated Lesson";
  } else {
    return "Unknown";
  }
}

function getActionItemAssignmentType(item: ActionItem): AssignmentType {
  if (item.contentTypeTitle === "One-Time Survey") {
    return "One-Time Survey";
  } else if (item.contentTypeTitle === "Lesson") {
    return "Lesson";
  } else if (item.contentTypeTitle === "Team Evaluation") {
    return "Team Evaluation";
  } else {
    return "Unknown";
  }
}

export async function getAssignments(): Promise<Assignment[]> {
  const [collections, items] = await Promise.all([
    getActionItemCollections(),
    getActionItems(),
  ]);
  const collectionAssignments: Assignment[] = collections.map(
    actionItemCollectionToAssignment
  );
  const actionItemAssignments: Assignment[] = items.map(actionItemToAssignment);
  return collectionAssignments.concat(actionItemAssignments);
}
