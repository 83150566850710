import { watch } from "vue";
import { storeToRefs } from "pinia";
import { useSnapshotStore } from "@/stores/snapshot";
import { useScheduleStore } from "@/stores/schedule";

export default function useSnapshots() {
  const scheduleStore = useScheduleStore();
  const snapshotStore = useSnapshotStore();
  const { elements } = storeToRefs(scheduleStore);
  watch(elements, () => {
    snapshotStore.snap(scheduleStore.$state);
  });
}
