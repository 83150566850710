import { record_event } from "@/scripts/team-coach-scripts.js";

export function initializeAccordions() {
  $(".accordion").accordion({
    active: false,
    collapsible: true,
    heightStyle: "content",
    activate: function (event, ui) {
      var newHead = ui.newHeader;
      var oldHead = ui.oldHeader;
      let note;
      if (!newHead.length) {
        note = oldHead.text().trim();
        record_event("AccordionClosed", note);
      } else {
        note = newHead.text().trim();
        record_event("AccordionOpened", note);
      }
    },
  });
}
