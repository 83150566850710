import { DateTime } from "luxon";
import apiClient from "@/services/client";

type ISODateTimeString = string;

interface APIMilestone {
  id: string;
  name: string;
  timestamp: ISODateTimeString;
}

interface APIBlueprint {
  id: string;
  name: string;
  milestones: APIMilestone[];
}

interface APIDemoCohort {
  id: number;
  cohort_id: number;
  blueprints: APIBlueprint[];
}

export interface Milestone {
  id: string;
  name: string;
  timestamp: DateTime;
}

export interface Blueprint {
  id: string;
  name: string;
  milestones: Milestone[];
}

export interface DemoCohort {
  id: number;
  cohortId: number;
  blueprints: Blueprint[];
}

const toMilestone = (milestone: APIMilestone): Milestone => ({
  ...milestone,
  timestamp: DateTime.fromISO(milestone.timestamp),
});

const toBlueprint = (blueprint: APIBlueprint): Blueprint => ({
  ...blueprint,
  milestones: blueprint.milestones.map(toMilestone),
});

const toDemoCohort = (demoCohort: APIDemoCohort): DemoCohort => ({
  ...demoCohort,
  cohortId: demoCohort.cohort_id,
  blueprints: demoCohort.blueprints.map(toBlueprint),
});

export const getDemoCohort = async (
  demoCohortId: number
): Promise<DemoCohort> => {
  const { data } = await apiClient.get<APIDemoCohort>(
    `/snapshots/api/demo-cohorts/${demoCohortId}/`
  );
  return toDemoCohort(data);
};

export const resetDemoCohort = async (demoCohortId: number) => {
  await apiClient.post(`/snapshots/api/demo-cohorts/${demoCohortId}/reset/`);
};

export const buildBlueprint = async (
  demoCohortId: number,
  blueprintId: string,
  timestamp: DateTime
) => {
  await apiClient.post(`/snapshots/api/demo-cohorts/${demoCohortId}/build/`, {
    blueprint_id: blueprintId,
    timestamp: timestamp.toISO(),
  });
};
