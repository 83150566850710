$(document).ready(function () {
  initializeRequired();
});

function initializeRequired() {
  const allFields = $("input, textarea, select");
  var hasCKEditor = $(".hasCKEditor");

  checkRequired();
  allFields.on("keyup", checkRequired);
  allFields.on("change", checkRequired);

  if (hasCKEditor.length > 0) {
    var CKEditorInput = CKEDITOR.instances.id_content;

    CKEditorInput.on("key", checkRequired);
  }
}

export function checkRequired() {
  const allFields = $("input, textarea, select");
  const requiredFields = [];
  let emptyRequired = 0;

  allFields.each(function () {
    var me = $(this);

    if (
      me.is("[required]:visible") ||
      me.hasClass("hasCKEditor CKEditorRequired")
    ) {
      requiredFields.push(me);
    }
  });

  setTimeout(function () {
    requiredFields.forEach(function (field) {
      const hasCKEditor = $(".hasCKEditor");

      if (hasCKEditor.length > 0) {
        const CKEditorField =
          field.context.className ===
          "dynamic-required hasCKEditor CKEditorRequired";
        const CKEditorInput = CKEDITOR.instances.id_content;
        const CKEditorisEmpty = CKEditorInput.getData().length == 0;

        if (hasCKEditor.parents(".survey_question:visible").length > 0) {
          if (
            !CKEditorField &&
            (field.val() == null || field.val().length == 0 || CKEditorisEmpty)
          ) {
            emptyRequired++;
          }
        } else {
          if (
            !CKEditorField &&
            (field.val() == null || field.val().length == 0)
          ) {
            emptyRequired++;
          }
        }
      } else {
        if (field.val() == null || field.val().length == 0) {
          emptyRequired++;
        }
      }
    });

    if (emptyRequired > 0) {
      $(".form-buttons .btn").not(".btn-cancel").prop("disabled", true);
    } else {
      $(".form-buttons .btn").prop("disabled", false);
    }
  }, 10);
}
