import { addTooltipContainer, tooltipHidden } from "@/scripts/tool-tip.js";

export function initializeCommentModal() {
  $("#id_text").trigger("focus");

  $("#id_text").on("keyup", function () {
    if ($(this).val().length) {
      $("#post-comment").attr("disabled", false);
    } else {
      $("#post-comment").attr("disabled", true);
    }
  });

  $("#post-comment").on("click", function (e) {
    e.preventDefault();
    postComment();
    return false;
  });

  $(document).on("click", ".delete-comment", function (e) {
    e.preventDefault();
    var commentId = $(this).attr("data-comment-id");
    var deleteCommentURL = $(this).attr("data-url");
    var csrfToken = $(this).parents(".delete-comment-form").attr("data-csrf");
    var commentDiv = $(this).parents(".comment");
    deleteComment(commentDiv, commentId, deleteCommentURL, csrfToken);
    tooltipHidden();
    return false;
  });
}

function deleteComment(commentDiv, commentId, deleteCommentURL, csrfToken) {
  $.ajax({
    url: deleteCommentURL,
    type: "POST",
    data: {
      commentId: commentId,
      csrfmiddlewaretoken: csrfToken,
    },
    success: function () {
      commentDiv.remove();
    },
  });
}

function postComment() {
  var postComment = $("#post-comment");
  var addCommentURL = postComment.attr("data-url");
  var targetType = postComment.attr("data-target");
  var targetId = postComment.attr("data-target-id");
  var csrfToken = $("#comment-form").attr("data-csrf");

  $.ajax({
    url: addCommentURL,
    type: "POST",
    data: {
      text: $("#id_text").val(),
      target: targetType,
      target_id: targetId,
      csrfmiddlewaretoken: csrfToken,
    },
    success: function (json) {
      $("#id_text").val("");
      $("#commentsContainer").prepend(json.html);
      $("#id_text").trigger("focus");
      addTooltipContainer();
    },
  });
}
