import { ref, onBeforeUnmount, watch } from "vue";
import type { Ref } from "vue";

const DEFAULT_CLEAR_TIMEOUT = 5500;

export default function useNotifications<Type>(
  enabled: Ref<boolean>,
  timeout = DEFAULT_CLEAR_TIMEOUT
) {
  const currentNotification = ref<Type>();
  let clearNotificationTimeout: ReturnType<typeof setTimeout>;

  const clearNotification = () => {
    currentNotification.value = undefined;
  };

  const updateNotification = (notification: Type) => {
    if (enabled.value) {
      clearTimeout(clearNotificationTimeout);
      currentNotification.value = notification;
    }
  };

  watch(currentNotification, () => {
    clearNotificationTimeout = setTimeout(clearNotification, timeout);
  });

  onBeforeUnmount(() => {
    clearTimeout(clearNotificationTimeout);
  });

  return {
    currentNotification,
    updateNotification,
    clearNotification,
  };
}
