function updateStatus() {
  let dataUrl = $("#getStatus").data("url");

  $.ajax({
    type: "GET",
    url: dataUrl,
    success: function (response) {
      if (response.state === "COMPLETED" && $(".inProgress").length) {
        clearInterval(window.statusTimer);
        if ($("#getStatus").data("no-redirect")) {
          $(".viewTeamFormationResults").removeClass("hidden");
        } else {
          location.reload();
        }
      }

      let statusSections = $("#statusDiv").find(".status-section");

      statusSections.each(function (i, section) {
        let statusType = $(section).data("status-type");
        let statusLabel = $(section).find("label");
        let statusBar = $(section).find(".status-bar");
        let statusBarContainer = $(section).find(".status-container");
        let currentAmount = "";
        let totalAmount = "";

        if (statusType == "iteration") {
          currentAmount = response.iteration;
          totalAmount = response.total_iterations;
        } else if (statusType == "section") {
          currentAmount = response.section;
          totalAmount = response.total_sections;
        }

        let statusPercent = (currentAmount / totalAmount) * 100;
        statusLabel.html(`${statusType}: ${currentAmount} / ${totalAmount}`);
        statusBar.css("width", `${statusPercent}%`);

        if (statusPercent == 100) {
          statusBarContainer.addClass("complete-bar");
        } else {
          statusBarContainer.removeClass("complete-bar");
        }
      });
      $("#statusDiv").find(".status-label").html(`${response.state}`);
    },
    error: function () {
      console.error("Error retrieving status");
    },
  });
}

export function addStatusListener() {
  $("#getStatus").on("click", updateStatus);

  if ($(".inProgress").length) {
    updateStatus();
    window.statusTimer = setInterval(updateStatus, 4000);
  }
}

$(document).ready(function () {
  addStatusListener();
});
