import apiClient from "@/services/client";

export interface APICustomTask {
  id: number;
  midterm_tasks?: string[];
  end_of_term_tasks?: string[];
}

export interface APIPostCustomTaskPayload {
  id?: number;
  midterm_tasks?: string[];
  end_of_term_tasks?: string[];
}

export const getCustomTasks = async (
  provisionalCohortId: number
): Promise<APICustomTask> => {
  const { data } = await apiClient.get<APICustomTask>(
    `/console/api/provisional-cohorts/${provisionalCohortId}/custom-tasks/`
  );
  return data;
};

export const createCustomTasks = async (
  provisionalCohortId: number,
  payload: APIPostCustomTaskPayload
): Promise<APICustomTask> => {
  const { data } = await apiClient.patch<APICustomTask>(
    `/console/api/provisional-cohorts/${provisionalCohortId}/custom-tasks/`,
    payload
  );
  return data;
};
