
import { PropType, defineComponent } from "vue";
import { DateTime, DateObjectUnits } from "luxon";
import { ISODateString } from "@/core";

export default defineComponent({
  props: {
    modelValue: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    defaults: {
      type: Object as PropType<DateObjectUnits>,
      default: () => ({}),
    },
    minDate: {
      type: Object as PropType<DateTime>,
      default: DateTime.fromObject({ year: 2000, month: 1, day: 1 }),
    },
    maxDate: {
      type: Object as PropType<DateTime>,
      default: DateTime.fromObject({ year: 3000, month: 12, day: 31 }),
    },
    helpText: {
      type: String,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    value: {
      get(): ISODateString {
        return this.modelValue.toISODate();
      },
      set(value: string) {
        const dt = DateTime.fromISO(value).set(this.defaults);
        if (dt.isValid) {
          this.$emit("update:modelValue", dt);
        }
      },
    },
  },
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
});
