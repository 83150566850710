import {
  getSingleBarLayout,
  getMidtermGraphLayout,
  getMidtermGraphBars,
  getGraphData,
} from "@/scripts/plotly-viz-utils.js";
import {
  appendChartDescription,
  addPlotlyAriaAttributes,
} from "@/scripts/accessibility-helpers.js";

export function memberWorkGraph(
  { SELECTOR } = { SELECTOR: "#midtermGraphWork" }
) {
  var DESCRIPTION_ID = "midtermGraphWorkDescription";
  var url = $(SELECTOR).data("url");

  getGraphData(url)
    .then((data) => {
      if (!url) {
        throw "No URL provided for initGraph()";
      }

      var myBar = getMidtermGraphBars()[0];
      var teamBar = getMidtermGraphBars()[1];

      var layout = getMidtermGraphLayout();
      var barList = [];

      myBar.x = data.categories;
      myBar.y = data.aValues;

      teamBar.x = data.categories;
      teamBar.y = data.bValues;

      if (!myBar.y || !teamBar.y) {
        layout = getSingleBarLayout();
        barList = !myBar.y ? [teamBar] : [myBar];
      } else {
        barList = [myBar, teamBar];
      }
      Plotly.newPlot($(SELECTOR).get(0), barList, layout, {
        displayModeBar: false,
        responsive: true,
      });

      appendChartDescription(SELECTOR, DESCRIPTION_ID, data.description);
      addPlotlyAriaAttributes(SELECTOR, DESCRIPTION_ID);
    })
    .catch((error) => {
      console.error(error);
    });
}

window.memberWorkGraph = memberWorkGraph;
