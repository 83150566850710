import emitter from "tiny-emitter/instance";

import {
  unbindAccordion,
  initializeAccordion,
} from "@/scripts/faculty/card-accordion.js";
import { clearDateTime } from "@/scripts/faculty/email-helpers.js";
import { tooltipHidden } from "@/scripts/tool-tip.js";

var modalNavigationHistory = [];

const displayModalLoader = () => {
  const modalLoader = $(".modal-loader");
  const modalContent = $(".modal-content:visible");
  modalLoader.removeClass("hidden");
  modalContent.addClass("hidden");
  return () => {
    modalLoader.addClass("hidden");
    modalContent.removeClass("hidden");
  };
};

function updateModalContent(url) {
  var activeModal = $(".modal-content:visible");
  const hideModalLoader = displayModalLoader();
  activeModal.load(url, function () {
    hideModalLoader();
    if (modalNavigationHistory.length > 0) {
      activeModal.prepend(`
                <button
                    class="btn btn-back btn-circle-small"
                    id="modalBackButton"
                    type="button"
                    aria-label="Previous Modal">
                </button>
            `);
      $("#modalBackButton").on("click", loadPreviousModal);
    }

    if (typeof initializeAccordion === "function") {
      initializeAccordion();
    }

    modalAccessibility();
    tooltipHidden();
  });
}

function loadPreviousModal() {
  let prevUrl = modalNavigationHistory.pop();
  updateModalContent(prevUrl);
}

export function loadPreviousOrCloseModal() {
  if (modalNavigationHistory.length === 0) {
    closeModal();
  } else {
    loadPreviousModal();
  }
}

export function loadSecondaryModal() {
  var activeModalContainer = $(".modal-main.show");
  var prevUrl = $(this).data("prevUrl");
  var url = $(this).data("url");

  modalNavigationHistory.push(prevUrl);
  tooltipHidden();
  updateModalContent(url);
  activeModalContainer.scrollTop(0);
}

export function openModal(modal, url) {
  const $modal = $(modal);

  closeModal();

  $("body").addClass("modal-open");

  const modalMain = $(".modal-main", $modal);
  const modalContent = $(".modal-content", $modal);

  modalMain.addClass("show");
  $modal.addClass("show");

  if (url) {
    const hideLoader = displayModalLoader();
    modalContent.load(url, function () {
      hideLoader();
      modalAccessibility();
      initializeAccordion();
    });
  } else {
    modalAccessibility();
    initializeAccordion();
  }

  $(modalMain).on("DOMNodeInserted", function () {
    modalAccessibility();
  });

  setTimeout(() => emitter.emit("modal-open"), 0);

  if (typeof initializeAccordion === "function") {
    initializeAccordion();
  }
}

export function showModal(e) {
  modalNavigationHistory = [];
  var senderElement = e.target;
  var me = $(this);

  if ($(senderElement).is(".stopPropogation")) {
    return true;
  } else if ($(senderElement).is(".fc-button, .fc-content, .fc-title")) {
    // FullCalendar STOP PROPAGATION
    return true;
  }

  let url;
  let modalConnector;
  if ($(senderElement).hasClass("modalTriggerOpen")) {
    modalConnector = $(senderElement).data("purpose");
    url = $(senderElement).data("url");
  } else {
    modalConnector = me.data("purpose");
    url = me.data("url");
  }

  const modal = $(
    `.modal-container:has(.modal-main[data-modal-connect=${modalConnector}])`
  );
  openModal(modal, url);
}

export function closeModal() {
  var modal = $(".modal-container");
  var modalContent = $(".modal-content");
  var modalMain = $(".modal-main");
  var modalDrilldown = $(this).parents(".modal-drilldown");
  const currentlyOpenModel = $(".modal-container.show");
  const notificationUrl = currentlyOpenModel.data("close-notification-url");

  if (modalDrilldown.length > 0) {
    modalContent.filter(".modal-clear-on-close").empty();
  }

  modal.removeClass("show");
  modalContent.removeClass("show");
  modalMain.removeClass("show");
  $("body").removeClass("modal-open");

  if (
    modal.find(".modal-main").data("modal-connect") == "triggerScheduleEmail"
  ) {
    clearDateTime();
  }

  if (typeof initializeAccordion === "function") {
    initializeAccordion();
  }

  if (typeof unbindAccordion === "function") {
    unbindAccordion();
  }

  if (notificationUrl) {
    $.post(notificationUrl);
  }
}

const notHiddenSelect2 = (element) =>
  !$(element).hasClass("select2-hidden-accessible");
const doesNotHaveHiddenParent = (element) =>
  !$(element).closest(".hidden").length;
const notHidden = (element) =>
  doesNotHaveHiddenParent(element) && notHiddenSelect2(element);

export function modalAccessibility() {
  let focusableElements =
    'button:not([disabled]), [href]:visible, input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])';
  let modal = $(".modal.show .modal-main.show");

  let focusableContent = modal
    .find(focusableElements)
    .filter((index, element) => notHidden(element));
  let firstFocusableElement = focusableContent[0];
  let lastFocusableElement = focusableContent[focusableContent.length - 1];

  $(document).on("keydown", function (e) {
    let tab = e.key === "Tab" || e.keyCode === 9;
    let escape = e.keyCode === 27;

    if (escape) {
      $(".btn-close").trigger("click");
      $(".btn-cancel").trigger("click");
    } else if (!tab) {
      return;
    }

    if (e.shiftKey) {
      if (document.activeElement === firstFocusableElement) {
        focusableContent = modal.find(focusableElements);
        lastFocusableElement = focusableContent[focusableContent.length - 1];

        lastFocusableElement.focus();
        e.preventDefault();
      }
    } else {
      if (document.activeElement === lastFocusableElement) {
        focusableContent = modal.find(focusableElements);
        firstFocusableElement = focusableContent[0];

        firstFocusableElement.focus();
        e.preventDefault();
      }
    }
  });

  if (firstFocusableElement) {
    firstFocusableElement.focus();
  }
}
