
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { DateTime } from "luxon";
import type {
  CalendarOptions,
  DateSelectArg,
  EventChangeArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PlannerEventCreateForm from "@/components/PlannerEventCreateForm.vue";
import PlannerErrors from "@/components/PlannerErrors.vue";
import EditMilestoneForm from "@/components/PlannerEditMilestoneForm.vue";
import EditAssignmentForm from "@/components/PlannerEditAssignmentForm.vue";
import ExtendDeadlineForm from "@/components/PlannerExtendDeadlineForm.vue";
import PlannerCalendarEvent from "@/components/PlannerCalendarEvent.vue";
import {
  toEventUpdateData,
  ScheduleError,
  parseFullCalendarDates,
  EventUpdateData,
  EventCreateData,
  isMilestoneEvent,
  isAssignmentEvent,
  CalendarEvent,
  Assignment,
  ScheduleElement,
  Milestone,
  clampedDateTime,
} from "@/core";
import { useSnapshotStore } from "@/stores/snapshot";
import { useScheduleStore } from "@/stores/schedule";

interface EventData {
  publishDate: DateTime;
  dueDate: DateTime;
}

const baseCalendarOptions = {
  defaultAllDay: true,
  initialView: "dayGridMonth",
  plugins: [dayGridPlugin, interactionPlugin],
  editable: true,
  selectable: true,
  droppable: false,
  dayHeaderFormat: {
    weekday: "narrow" as "narrow",
  },
};

export default defineComponent({
  components: {
    FullCalendar,
    PlannerEventCreateForm,
    PlannerErrors,
    EditAssignmentForm,
    EditMilestoneForm,
    PlannerCalendarEvent,
    ExtendDeadlineForm,
  },
  data() {
    return {
      selectedId: null as string | null,
      newEvent: null as EventData | null,
    };
  },
  computed: {
    ...mapState(useScheduleStore, [
      "calendarEvents",
      "errors",
      "hasErrors",
      "assignableContent",
      "minDateTime",
      "maxDateTime",
    ]),
    ...mapState(useSnapshotStore, ["canUndo", "canRedo"]),
    showModal: {
      get(): boolean {
        return this.newEvent !== null;
      },
      set(value: any) {
        if (!value) {
          this.newEvent = null;
        }
      },
    },
    initialDate(): DateTime {
      return clampedDateTime(
        DateTime.now(),
        this.minDateTime,
        this.maxDateTime
      );
    },
    rightToolbar() {
      const undoButton: string = this.canUndo
        ? "undoButton"
        : "undoDisabledButton";
      const redoButton: string = this.canRedo
        ? "redoButton"
        : "redoDisabledButton";
      return `today prev,next ${undoButton},${redoButton}`;
    },
    calendarOptions(): CalendarOptions {
      return {
        ...baseCalendarOptions,
        select: this.handleSelect,
        eventClick: this.handleEventClick,
        eventChange: this.handleEventChange,
        events: this.calendarEvents,
        contentHeight: "auto",
        customButtons: {
          redoButton: {
            text: "",
            click: this.redo,
            hint: "Redo action",
          },
          undoButton: {
            text: "",
            click: this.undo,
            hint: "Undo action",
          },
          redoDisabledButton: {
            text: "",
            hint: "Redo action",
          },
          undoDisabledButton: {
            text: "",
            hint: "Redo action",
          },
        },
        headerToolbar: {
          left: "title",
          center: "",
          right: this.rightToolbar,
        },
        initialDate: this.initialDate.toISODate(),
      };
    },
    selectedEvent(): CalendarEvent | undefined {
      return this.calendarEvents.find((e) => e.id === this.selectedId);
    },
    selectedMilestone():
      | {
          milestone: Milestone;
          event: CalendarEvent;
          element: ScheduleElement;
        }
      | undefined {
      if (!this.selectedEvent) return;
      const { eventType, element } = this.selectedEvent.extendedProps;
      if (isMilestoneEvent(eventType)) {
        return {
          event: this.selectedEvent,
          milestone: eventType,
          element: element,
        };
      }
      return undefined;
    },
    selectedAssignment():
      | {
          assignment: Assignment;
          event: CalendarEvent;
          element: ScheduleElement;
        }
      | undefined {
      if (!this.selectedEvent) return;
      const { eventType, element } = this.selectedEvent.extendedProps;
      if (isAssignmentEvent(eventType)) {
        return {
          event: this.selectedEvent,
          assignment: eventType,
          element: element,
        };
      }
      return undefined;
    },
  },
  methods: {
    ...mapActions(useScheduleStore, [
      "createEvent",
      "updateEvent",
      "deleteEvent",
    ]),
    ...mapActions(useSnapshotStore, ["undo", "redo"]),
    isMilestoneEvent,
    isAssignmentEvent,
    getCalendarApi() {
      const calendar = this.$refs.calendar as typeof FullCalendar;
      return calendar.getApi();
    },
    handleEventClick({ event }: { event: any }) {
      this.selectedId = event.id;
    },
    handleRemoveEvent(fullCalendarEvent: any) {
      this.deleteEvent(fullCalendarEvent.id);
    },
    handleEventChange(eventChangeArgs: EventChangeArg) {
      const { event } = eventChangeArgs;
      const updates = toEventUpdateData(event);
      event.remove();
      this.updateEvent(event.id, updates);
    },
    handleEventUpdate(eventData: EventUpdateData) {
      if (this.selectedId) {
        this.updateEvent(this.selectedId, eventData);
        this.selectedId = null;
      }
    },
    handleEventCreate(eventData: EventCreateData) {
      this.createEvent(eventData);
      this.newEvent = null;
      this.showModal = false;
    },
    showAssignmentModal(newEvent: EventData) {
      this.newEvent = null;
      this.newEvent = newEvent;
      this.showModal = true;
    },
    handleSelect(dateSelection: DateSelectArg) {
      const fullCalendarDates = parseFullCalendarDates(dateSelection);
      this.showAssignmentModal(fullCalendarDates);
    },
    openCalendarTo(datetime: DateTime) {
      this.getCalendarApi().gotoDate(datetime.toJSDate());
    },
    handleShowErrorDetails(error: ScheduleError) {
      if (error.instance) {
        this.openCalendarTo(error.instance.publishDate);
      }
    },
  },
  watch: {
    hasErrors() {
      this.$nextTick(() => {
        this.getCalendarApi().updateSize();
      });
    },
  },
});
