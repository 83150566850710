<template>
  <select />
</template>
<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Number,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  mounted: function () {
    const vm = this;
    $(this.$el)
      .select2({
        data: this.options,
        placeholder: this.placeholder,
        multiple: this.multiple,
      })
      .on("select2:select", function (e) {
        vm.$emit("update:modelValue", parseInt(e.params.data.id));
      });
  },
  watch: {
    modelValue: {
      immediate: true,
      handler: function (value) {
        $(this.$el).val(value);
        $(this.$el).trigger("change");
      },
    },
    options(data) {
      $(this.$el).empty().select2({
        data,
      });
    },
  },
  unmounted: function () {
    $(this.$el).off().select2("destroy");
  },
};
</script>
