export function initializeAccordion() {
  $(".btn-accordion").on("click", toggleAccordion);
}

export function unbindAccordion() {
  $(".btn-accordion").off("click", toggleAccordion);
}

function toggleAccordion() {
  var me = $(this);
  var accordionContent = me.parents(".accordion-block");

  if (accordionContent.hasClass("open")) {
    accordionContent.removeClass("open");
  } else {
    accordionContent.addClass("open");
  }
}
