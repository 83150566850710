
import { defineComponent, PropType } from "vue";
import { DateTime } from "luxon";
import { TANDEM_DATE_SHORT } from "@/core";
export default defineComponent({
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    publishDate: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    dueDate: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    cohort: {
      type: String,
      required: true,
    },
  },
  computed: {
    publishDateShort(): string {
      if (!this.publishDate.isValid) {
        return "No publish date";
      }
      return this.publishDate.toFormat(TANDEM_DATE_SHORT);
    },
    dueDateShort(): string {
      if (!this.dueDate.isValid) {
        return "No due date";
      }
      return this.dueDate.toFormat(TANDEM_DATE_SHORT);
    },
  },
});
