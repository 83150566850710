import { ref, computed, watch } from "vue";
import { debounce } from "ts-debounce";
import { useScheduleStore } from "@/stores/schedule";
import {
  createSchedulePlan,
  getSchedulePlan,
  finalizeSchedulePlan,
  initializeSchedulePlan,
} from "@/services/schedulePlanService";
import { SchedulePlan, elementsChanged, ScheduleElement } from "@/core";

const DEBOUNCE_MILLIS = 500;

type ID = number | string;
type ScheduleSyncStatus = "dirty" | "syncing" | "valid" | "invalid" | "init";

export default function useSchedulePlanSync(cohortId: ID) {
  const scheduleStore = useScheduleStore();
  const currentPlan = ref<SchedulePlan>();
  const syncing = ref<boolean>(false);
  const currentPlanId = computed(() => currentPlan.value?.id);
  const hasUnsavedChanges = computed(
    () =>
      !!currentPlan.value &&
      elementsChanged(currentPlan.value.elements, scheduleStore.elements)
  );
  const syncStatus = computed<ScheduleSyncStatus>(() => {
    if (syncing.value === true) {
      return "syncing";
    } else if (!currentPlan.value) {
      return "init";
    } else if (hasUnsavedChanges.value === true) {
      return "dirty";
    } else {
      return currentPlan.value?.isValid ? "valid" : "invalid";
    }
  });
  const canFinalize = computed(() => syncStatus.value === "valid");

  async function saveSchedulePlan(
    previousPlanId: number,
    elements: ScheduleElement[]
  ) {
    try {
      syncing.value = true;
      const plan = await createSchedulePlan(cohortId, previousPlanId, elements);
      scheduleStore.syncSchedulePlan(plan);
      currentPlan.value = plan;
    } finally {
      syncing.value = false;
    }
  }

  const debouncedSavePlan = debounce(saveSchedulePlan, DEBOUNCE_MILLIS);

  async function loadSchedulePlan(schedulePlanId: ID) {
    const plan = await getSchedulePlan(cohortId, schedulePlanId);
    scheduleStore.applySchedulePlan(plan);
    currentPlan.value = plan;
  }

  async function initializePlan() {
    const plan = await initializeSchedulePlan(cohortId);
    scheduleStore.applySchedulePlan(plan);
    currentPlan.value = plan;
  }

  const finalizePlan = async () => {
    if (!currentPlanId.value) return;
    await finalizeSchedulePlan(cohortId, currentPlanId.value);
  };

  watch(
    [() => scheduleStore.elements, syncing],
    async ([elements, syncing]) => {
      if (hasUnsavedChanges.value && !syncing && currentPlan.value) {
        await debouncedSavePlan(currentPlan.value.id, elements);
      }
    },
    { deep: true }
  );

  return {
    currentPlanId,
    currentPlan,
    loadSchedulePlan,
    finalizePlan,
    initializePlan,
    syncStatus,
    canFinalize,
  };
}
