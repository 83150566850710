import {
  initializeAccordion,
  unbindAccordion,
} from "@/scripts/faculty/card-accordion.js";
import { addTooltipContainer } from "@/scripts/tool-tip.js";
import { colorCode } from "@/scripts/faculty/member-eval/color-assignment.js";
import { renderMemberGraphs } from "@/scripts/student/render-member-graphs.js";

const ID_SELECT_INDIVIDUAL = "#individuals";
const ID_SELECT_TEAM = "#teams";
const ID_TEAM_RESULTS_CONTAINER = "#team-results";
const ID_MEMBER_RESULTS_CONTAINER = "#member-results";
const ID_LOADER_CONTAINER = "#overview-loading";
const STAT_SELECT_CLASSNAME = ".stats-select";

const select2Config = {
  minimumResultsForSearch: 10,
};

function displayLoader(containerId) {
  const resultsContainer = $(containerId);
  resultsContainer.empty();
  $(ID_LOADER_CONTAINER).removeClass("hidden");
}

function hideLoader() {
  $(ID_LOADER_CONTAINER).addClass("hidden");
}

function renderContent(containerId, htmlContents) {
  const resultsContainer = $(containerId);
  resultsContainer.empty();
  htmlContents.forEach((contentHtml) => {
    resultsContainer.append(contentHtml);
  });
}

function displayError(containerId, error) {
  const resultsContainer = $(containerId);
  resultsContainer.empty();
  const errorContent = $("<p>").text(`Error: ${error.statusText}`);
  resultsContainer.append(errorContent);
}

const loadSelectedContent =
  (containerId, onSuccessCallback) =>
  ({ target }) => {
    const selectedOption = $(target).find(":selected");
    const url = selectedOption && selectedOption.data("url");
    if (url) {
      displayLoader(containerId);
      $.ajax({
        method: "GET",
        url: url,
        success: (data) => {
          hideLoader();
          renderContent(containerId, data.htmlContents);
          unbindAccordion();
          initializeAccordion();
          addTooltipContainer();
          colorCode();
          if (onSuccessCallback) {
            onSuccessCallback();
          }
        },
        error: (err) => {
          displayError(err);
        },
      });
    }
  };

const handleTeamSelect = loadSelectedContent(ID_TEAM_RESULTS_CONTAINER);

const handleIndividualSelect = loadSelectedContent(
  ID_MEMBER_RESULTS_CONTAINER,
  () => {
    renderMemberGraphs();
  }
);

export function initializeEvalOverview() {
  $(ID_SELECT_TEAM).on("change", handleTeamSelect).trigger("change");
  $(ID_SELECT_INDIVIDUAL)
    .on("change", handleIndividualSelect)
    .trigger("change");
  $(STAT_SELECT_CLASSNAME).select2(select2Config);
  initializeAccordion();
}
