
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    modelValue: {
      type: [Array, Boolean],
      required: true,
    },
    label: { type: String, required: true },
  },
  emits: ["update:modelValue"],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
  },
});
