import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "filter-item" }
const _hoisted_2 = { class: "filter-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _withDirectives(_createElementVNode("input", _mergeProps({ type: "checkbox" }, _ctx.$attrs, {
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
      }), null, 16), [
        [_vModelCheckbox, _ctx.model]
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
    ])
  ]))
}