import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "survey_question" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "helper-text"
}
const _hoisted_4 = { class: "survey_responses" }
const _hoisted_5 = ["name", "min", "max"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: "survey_prompt",
      for: _ctx.name
    }, _toDisplayString(_ctx.label), 9, _hoisted_2),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        type: "date",
        name: _ctx.name,
        min: (_ctx.minDate && _ctx.minDate.toISODate()),
        max: (_ctx.maxDate && _ctx.maxDate.toISODate()),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event))
      }, null, 8, _hoisted_5), [
        [_vModelText, _ctx.value]
      ])
    ])
  ]))
}