import { defineStore } from "pinia";
import { useScheduleStore, SchedulePlanState } from "@/stores/schedule";
import { elementsChanged } from "@/core";

function cloneScheduleState(state: SchedulePlanState): SchedulePlanState {
  return {
    elements: state.elements.map((e) => ({ ...e })),
    eventTypes: state.eventTypes.map((c) => ({ ...c })),
    errors: state.errors.map((e) => ({ ...e })),
  };
}

interface SnapshotState {
  undos: SchedulePlanState[];
  redos: SchedulePlanState[];
  current: SchedulePlanState | undefined;
}

export const useSnapshotStore = defineStore("snapshots", {
  state: (): SnapshotState => ({
    undos: [],
    redos: [],
    current: undefined,
  }),
  getters: {
    canUndo: (state): boolean => state.undos.length > 0,
    canRedo: (state): boolean => state.redos.length > 0,
    snapshotContainsChanges:
      (state) =>
      (snapshot: SchedulePlanState): boolean => {
        if (!state.current) {
          return true;
        }
        return elementsChanged(state.current.elements, snapshot.elements);
      },
  },
  actions: {
    snap(snapshot: SchedulePlanState) {
      if (this.snapshotContainsChanges(snapshot)) {
        if (this.current) this.undos.push(cloneScheduleState(this.current));
        this.current = cloneScheduleState(snapshot);
        this.redos = [];
      }
    },
    redo() {
      if (this.current) {
        this.undos.push(cloneScheduleState(this.current));
        this.current = this.redos.pop();
        this.push();
      }
    },
    undo() {
      if (this.current) {
        this.redos.push(cloneScheduleState(this.current));
        this.current = this.undos.pop();
        this.push();
      }
    },
    push() {
      if (this.current) {
        const store = useScheduleStore();
        store.loadSnapshot(this.current);
      }
    },
  },
});
