
import { PropType, defineComponent } from "vue";
import { DateTime } from "luxon";
import { groupBy } from "lodash";
import TandemDateInput from "@/components/TandemDateInput.vue";
import TandemSelect from "@/components/TandemSelect.vue";
import {
  EventTypeCategory,
  Assignment,
  EventCreateData,
  categoryLabel,
} from "@/core";

interface AssignmentGroup {
  label: string;
  options: Assignment[];
}

export default defineComponent({
  props: {
    initialStartDate: {
      type: Object as PropType<DateTime>,
      default: null,
    },
    initialEndDate: {
      type: Object as PropType<DateTime>,
      default: null,
    },
    contentOptions: {
      type: Array as PropType<Assignment[]>,
      required: true,
    },
  },
  components: {
    TandemDateInput,
    TandemSelect,
  },
  emits: ["submit"],
  data() {
    return {
      publishDate: this.initialStartDate,
      dueDate: this.initialEndDate,
      assignment: this.contentOptions[0],
    };
  },
  computed: {
    formValid() {
      return (
        this.publishDate &&
        this.dueDate &&
        this.assignment &&
        this.publishDate < this.dueDate
      );
    },
    groups(): AssignmentGroup[] {
      return Object.entries(groupBy(this.contentOptions, "type")).map(
        ([label, options]) => ({
          label: categoryLabel(label as EventTypeCategory),
          options,
        })
      );
    },
  },
  methods: {
    getLabel(assignment: Assignment) {
      return assignment.type === EventTypeCategory.Lesson &&
        !assignment.isSystemAssigned
        ? `${assignment.name} Lesson`
        : assignment.name;
    },
    getKey: (assignment: Assignment) => assignment.id,
    handleSubmit(event: Event) {
      event.preventDefault();
      const eventData: EventCreateData = {
        publishDate: this.publishDate,
        dueDate: this.dueDate,
        assignment: this.assignment,
      };
      this.$emit("submit", eventData);
    },
  },
  watch: {
    publishDate(newDate: DateTime, prevDate: DateTime) {
      const pubDueDifference = this.dueDate.diff(prevDate).as("days");
      this.dueDate = newDate.plus({ days: pubDueDifference });
    },
  },
});
