import apiClient from "@/services/client";

export interface APICohortMember {
  id: number;
  name: string;
}

export interface APITeam {
  id: number;
  name: string;
  cohort_members: APICohortMember[];
}

export const getTeams = async (cohortId: number): Promise<APITeam[]> => {
  const { data } = await apiClient.get<APITeam[]>(
    `/api/cohorts/${cohortId}/teams`
  );
  return data;
};

export const getTeamEvaluationResults = async (
  cohortId: number,
  cohortSurveyId: number,
  teamId: number
): Promise<string> => {
  const { data } = await apiClient.get<string>(
    `/api/cohorts/${cohortId}/cohort-surveys/${cohortSurveyId}/results/${teamId}`
  );
  return data;
};
