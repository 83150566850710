<template>
  <div v-if="loading">
    <p>Loading</p>
  </div>
  <div v-else-if="error">
    <p>Error: {{ error }}</p>
  </div>
  <div v-else>
    <FaqList
      :items="faqs"
      :organization="organization"
    />
  </div>
</template>

<script>
import axios from "axios";
import FaqList from "@/components/FaqList.vue";

export default {
  components: {
    FaqList,
  },
  props: {
    faqApiEndpoint: {
      type: String,
      required: true,
    },
    organization: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      faqs: [],
      error: false,
    };
  },
  async created() {
    this.loading = true;
    try {
      const response = await axios.get(this.faqApiEndpoint);
      this.faqs = response.data;
    } catch (error) {
      this.error = error.message;
    }
    this.loading = false;
  },
};
</script>
