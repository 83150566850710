import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dates" }
const _hoisted_2 = { class: "button-block" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tandem_select = _resolveComponent("tandem-select")!
  const _component_tandem_date_input = _resolveComponent("tandem-date-input")!

  return (_openBlock(), _createElementBlock("form", null, [
    _createVNode(_component_tandem_select, {
      modelValue: _ctx.assignment,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assignment) = $event)),
      groups: _ctx.groups,
      label: "Assignment Type",
      "get-label": _ctx.getLabel,
      "get-key": _ctx.getKey
    }, null, 8, ["modelValue", "groups", "get-label", "get-key"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_tandem_date_input, {
        name: "publish-date",
        label: "Release Date",
        "help-text": "This assignment will release at 6:00AM.",
        defaults: { hour: 6, minute: 0 },
        modelValue: _ctx.publishDate,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.publishDate) = $event))
      }, null, 8, ["modelValue"]),
      _createVNode(_component_tandem_date_input, {
        name: "due-date",
        label: "Due Date",
        "help-text": "This assignment will be due at 11:59PM.",
        defaults: { hour: 23, minute: 59 },
        "min-date": _ctx.publishDate,
        modelValue: _ctx.dueDate,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dueDate) = $event))
      }, null, 8, ["min-date", "modelValue"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "submit",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args))),
        disabled: !_ctx.formValid,
        class: "btn btn-burg",
        value: "Add To Planner"
      }, null, 8, _hoisted_3)
    ])
  ]))
}