export function colorCode() {
  let key = $(".color-assign");
  let keyColors = $(key).children(".color-loop");
  let colorLibrary = new Object();

  keyColors.each(function (i, colorContainer) {
    let memberID = $(colorContainer).data("member-id");
    let memberColor = $(colorContainer)
      .children(".key-color")
      .css("background-color");
    colorLibrary[memberID] = memberColor;
  });

  assignBarPlotColors(colorLibrary);
}

function assignBarPlotColors(colorLibrary) {
  let plots = $(".member-response");

  plots.each(function (i, plot) {
    if ($(plot).hasClass("overlap")) {
      let individualResponses = $(plot).find(".response-details");
      individualResponses.each(function (i, response) {
        let currentMember = $(response).data("member-id");
        $(response)
          .find(".member-response.inside-tooltip")
          .css("background-color", colorLibrary[currentMember]);
      });
    } else {
      let currentMember = $(plot)
        .find(".member-response-details")
        .data("member-id");
      $(plot).css("background-color", colorLibrary[currentMember]);
      $(plot)
        .find(".member-response.inside-tooltip")
        .css("background-color", colorLibrary[currentMember]);
    }
  });
}
