import apiClient from "@/services/client";
import {
  APISchedulePlan,
  SchedulePlan,
  toSchedulePlan,
  ScheduleElement,
  toApiSchedulePlanCreatePayload,
} from "@/core";

const getSchedulePlan = async (
  cohortId: string | number,
  planId: string | number
): Promise<SchedulePlan> => {
  const { data } = await apiClient.get<APISchedulePlan>(
    `/planner/api/cohort/${cohortId}/schedule-plans/${planId}`
  );
  return toSchedulePlan(data);
};

const createSchedulePlan = async (
  cohortId: string | number,
  previousPlanId: number,
  elements: ScheduleElement[]
): Promise<SchedulePlan> => {
  const payload = toApiSchedulePlanCreatePayload(previousPlanId, elements);
  const { data } = await apiClient.post<APISchedulePlan>(
    `/planner/api/cohort/${cohortId}/schedule-plans/`,
    payload
  );
  return toSchedulePlan(data);
};

const finalizeSchedulePlan = async (
  cohortId: string | number,
  planId: string | number
): Promise<void> => {
  await apiClient.post(
    `/planner/api/cohort/${cohortId}/schedule-plans/${planId}/finalize/`
  );
};

const initializeSchedulePlan = async (
  cohortId: string | number
): Promise<SchedulePlan> => {
  const { data } = await apiClient.post<APISchedulePlan>(
    `/planner/api/cohort/${cohortId}/schedule-plans/initialize/`
  );
  return toSchedulePlan(data);
};

export {
  getSchedulePlan,
  createSchedulePlan,
  finalizeSchedulePlan,
  initializeSchedulePlan,
};
