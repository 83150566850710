<template>
  <div class="select-item">
    <span
      class="handle btn-shuffle btn-circle-small btn btn-white"
      aria-label="Shuffle input"
    />
    <input
      type="text"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
      @keyup.enter="$emit('next-item')"
      @paste.stop="onPaste"
    >
    <button
      type="button"
      class="btn btn-circle-small btn-delete btn-red"
      aria-label="Delete input"
      :disabled="value == ''"
      @click="$emit('remove-row')"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: null,
    },
  },
  emits: ["paste", "next-item", "remove-row", "input"],
  setup(props, { emit }) {
    const onPaste = (event) => {
      const pastedText = event.clipboardData.getData("text");
      const existingText = event.target.value;
      const selectionStart = event.target.selectionStart;
      const selectionEnd = event.target.selectionEnd;
      const newText =
        existingText.slice(0, selectionStart) +
        pastedText +
        existingText.slice(selectionEnd);
      setTimeout(() => {
        emit("paste", newText);
      }, 0);
      return false;
    };
    return { onPaste };
  },
});
</script>
