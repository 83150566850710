const hideTeamCheckContents = () => {
  $("[id^=tc-why]").hide();
};

const showTeamCheckContent = (event) => {
  hideTeamCheckContents();
  const showId = event.target.value;
  $(`#${showId}`).show();
};

export const initializeFeedbackAccordions = () => {
  hideTeamCheckContents();
  const modalContainer = $(".modal-container.show");
  const dropdownParent = modalContainer.is(":visible")
    ? modalContainer
    : $(document.body);
  const select2Config = {
    dropdownParent,
    minimumResultsForSearch: -1,
  };
  $("#team-check-select")
    .select2(select2Config)
    .on("change", showTeamCheckContent)
    .trigger("change");
};
