const modalContainer = $(".modal-container.show");
const dropdownParent = modalContainer.is(":visible")
  ? modalContainer
  : $(document.body);
const select2Config = {
  dropdownParent,
  minimumResultsForSearch: -1,
};
const LOADER_DELAY_MILLIS = 300;
const show = (element) => element.removeClass("hidden");
const hide = (element) => element.addClass("hidden");

function loadFeedbackContent() {
  const selectedOption = $(this).find(":selected");
  const url = selectedOption && selectedOption.data("url");
  const container = $("#tc-details-content");
  const loader = $("#tc-details-loader");
  const error = $("#tc-details-error");
  const loaderTimeout = setTimeout(() => hide(loader), LOADER_DELAY_MILLIS);
  loader.css("min-height", container.height());
  show(loader);
  hide(error);
  container.html(null);
  container.load(url, (_, status) => {
    clearTimeout(loaderTimeout);
    hide(loader);
    loader.css("min-height", 0);
    if (status === "error") {
      show(error);
    }
  });
}

export function initializeTeamCheckFeedbackModal() {
  const selectedTeamId = $(".team-menu").val();
  $("#team-select")
    .select2(select2Config)
    .on("change", loadFeedbackContent)
    .val((_, currentValue) => selectedTeamId || currentValue)
    .trigger("change");
}
