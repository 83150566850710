import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "fc-event-main-frame" }
const _hoisted_2 = { class: "fc-event-title-container" }
const _hoisted_3 = { class: "fc-event-title fc-sticky" }
const _hoisted_4 = {
  key: 0,
  class: "pub-tag"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.event.title) + " ", 1),
        (_ctx.published)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Published"))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}