
import { PropType, defineComponent } from "vue";
import PlannerEventCard from "@/components/PlannerEventCard.vue";
import TandemDateInput from "@/components/TandemDateInput.vue";
import { CalendarEvent, EventUpdateData, Milestone } from "@/core";

export default defineComponent({
  props: {
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
    milestone: {
      type: Object as PropType<Milestone>,
      required: true,
    },
  },
  components: {
    TandemDateInput,
    PlannerEventCard,
  },
  emits: ["remove", "submit"],
  data() {
    const { publishDate } = this.calendarEvent.extendedProps.element;
    return {
      publishDate,
    };
  },
  methods: {
    handleSubmit(event: Event) {
      event.preventDefault();
      const eventData: EventUpdateData = {
        publishDate: this.publishDate,
        dueDate: this.publishDate,
      };
      this.$emit("submit", eventData);
    },
  },
});
