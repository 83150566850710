import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cal-section schedule-calendar" }
const _hoisted_2 = { class: "planner-cal-container" }
const _hoisted_3 = { class: "planner-cal" }
const _hoisted_4 = { class: "schedule-calendar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_planner_calendar_event = _resolveComponent("planner-calendar-event")!
  const _component_cohort_calendar_card = _resolveComponent("cohort-calendar-card")!
  const _component_v_dropdown = _resolveComponent("v-dropdown")!
  const _component_full_calendar = _resolveComponent("full-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_full_calendar, {
          ref: "calendar",
          options: _ctx.calendarOptions
        }, {
          eventContent: _withCtx((arg) => [
            _createVNode(_component_v_dropdown, {
              triggers: ['click'],
              placement: "auto",
              "auto-boundary-max-size": ""
            }, {
              popper: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  (_ctx.selectedEvent)
                    ? (_openBlock(), _createBlock(_component_cohort_calendar_card, {
                        key: 0,
                        "calendar-event": _ctx.selectedEvent
                      }, null, 8, ["calendar-event"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              default: _withCtx(() => [
                _createVNode(_component_planner_calendar_event, {
                  event: arg.event
                }, null, 8, ["event"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["options"])
      ])
    ])
  ]))
}