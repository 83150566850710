import { defineStore } from "pinia";
import { getCalendarScheduleElements } from "@/services/calendarService";
import { toCalendarEvents } from "@/core";
import { min, max } from "lodash";

export const useCalendarStore = defineStore("calendar", {
  state: () => ({
    scheduleElements: [],
  }),
  getters: {
    calendarEvents: ({ scheduleElements }) =>
      toCalendarEvents(scheduleElements, []).map((event) => ({
        ...event,
        editable: false,
        startEditable: false,
        durationEditable: false,
      })),
    firstDate: ({ scheduleElements }) =>
      min(scheduleElements.map(({ publishDate }) => publishDate)),
    lastDate: ({ scheduleElements }) =>
      max(scheduleElements.map(({ dueDate }) => dueDate)),
    calendarRange() {
      return [this.firstDate, this.lastDate];
    },
  },
  actions: {
    async loadCalendarData(cohortId) {
      const elements = await getCalendarScheduleElements(cohortId);
      this.scheduleElements = elements;
    },
  },
});
