
import { PropType, defineComponent } from "vue";
import PlannerEventCard from "@/components/PlannerEventCard.vue";
import { isAssignmentEvent, TANDEM_DATE_SHORT } from "@/core";

import { CalendarEvent } from "@/core";
export default defineComponent({
  props: {
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
  },
  components: {
    PlannerEventCard,
  },
  computed: {
    startDate() {
      return this.calendarEvent.extendedProps.element.publishDate.toFormat(
        TANDEM_DATE_SHORT
      );
    },
    endDate() {
      return this.calendarEvent.extendedProps.element.dueDate.toFormat(
        TANDEM_DATE_SHORT
      );
    },
    eventType() {
      return this.calendarEvent.extendedProps.eventType;
    },
  },
  methods: {
    isAssignmentEvent,
  },
});
