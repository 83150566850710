<template>
  <span>
    <input
      type="hidden"
      :name="name"
      :value="jsonValues"
    >
    <TaskInput
      v-model="values"
      :min-num-values="minNum"
      :max-num-values="maxNum"
      :placeholders="placeholders"
    />
  </span>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import TaskInput from "./TaskInput.vue";

export default defineComponent({
  components: { TaskInput },
  props: {
    minNum: {
      type: Number,
      default: 1,
    },
    maxNum: {
      type: Number,
      default: 8,
    },
    name: {
      type: String,
      required: true,
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "[]",
    },
  },
  setup(props) {
    let initialValue = [];
    try {
      initialValue = JSON.parse(props.value);
    } catch {
      initialValue = [];
    }
    const values = ref(initialValue);

    const jsonValues = computed(() =>
      JSON.stringify(values.value.filter((v) => !!v))
    );

    return { values, jsonValues };
  },
});
</script>
