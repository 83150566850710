/* eslint-disable no-unused-vars */

import { tooltipHidden, tooltipVisible } from "@/scripts/tool-tip.js";
import { appendChartDescription } from "@/scripts/accessibility-helpers";

export var UNDRAWN_GRAPH_SELECTOR = ".d3-graph:not(:has(>svg))";
export var TEAM_GRAPH_SELECTOR = ".team-graph .d3-graph";
export var INDIVIDUAL_GRAPH_SELECTOR = ".individual-graph .d3-graph";
export var UNDRAWN_LEGENDS_SELECTOR = ".graph-key:empty";
export var LEGENDS_SELECTOR = ".graph-key";
export var MAX_INCREASE_SELECTOR = "#max-increase";
export var MAX_INCREASE_CONTENT_SELECTOR = "#max-increase .category";
export var MAX_DECREASE_SELECTOR = "#max-decrease";
export var MAX_DECREASE_CONTENT_SELECTOR = "#max-decrease .category";

export function initGraph(data, summary, selector, descriptionElementId) {
  if (!data || !data.length) {
    return;
  }

  appendChartDescription(selector, summary);

  var width = 550;
  var height = 225;
  var newHeight = 200;
  var margin = 50;
  var duration = 250;

  var lineOpacity = "1";
  var lineOpacityHover = "1";
  var otherLinesOpacityHover = "0.1";
  var lineStroke = "2.5px";
  var lineStrokeHover = "3.5px";

  var circleOpacity = "1";
  var circleOpacityOnLineHover = "0.25";
  var circleRadius = 4;
  var circleRadiusHover = 5;

  let vizToolTip;
  if ($(".visualization-tooltip").length > 0) {
    vizToolTip = d3
      .select(".visualization-tooltip")
      .attr("aria-hidden", "true");
  } else {
    vizToolTip = d3
      .select("body")
      .append("div")
      .attr("class", "visualization-tooltip active-tooltip tool-tip")
      .attr("aria-hidden", "true")
      .attr("role", "tooltip")
      .style("opacity", 0);
  }

  // FORMAT DATA
  // Create a list of hash map that save the label
  let list = [];
  for (let i = 0; i < data[0].values.length; i++) {
    //value idx
    let hash_map = new Map();
    try {
      for (let j = 0; j < data.length; j++) {
        //theme idx
        if (data[j].values[i] === undefined) {
          continue;
        }

        if (hash_map.has(data[j].values[i].score)) {
          hash_map.get(data[j].values[i].score).add(data[j].theme);
        } else {
          hash_map.set(data[j].values[i].score, new Set([data[j].theme]));
        }
      }
    } catch (error) {
      console.error(error);
    }
    list.push(hash_map);
  }

  data.forEach(function (theme, idx) {
    theme.values.forEach(function (d, idx) {
      d.labels = Array.from(list[idx].get(d.score));
      d.date = +d.date;
      if (d.score != null) {
        d.score = +d.score;
      }
    });
  });

  // SET RANGES
  var x = d3.scaleLinear().range([0, width]);
  var y = d3.scaleLinear().range([height, 0]);

  // SET SCALE
  var xScale = d3
    .scaleLinear()
    .domain(d3.extent(data[0].values, (d) => d.date)) // extent returns the minimum and maximum value in the given iterable
    .range([0, width - margin])
    .nice();

  var yScale = d3
    .scaleLinear()
    .domain(data[0].range)
    .range([0, height - margin])
    .nice();

  // LINE GENERATOR
  var line = d3
    .line()
    .defined((d) => d.score != null)
    .x((d) => xScale(d.date)) // set the x values for the line generator
    .y((d) => yScale(d.score)); // set the y values for the line generator

  var svg = d3
    .select(selector)
    .append("svg")
    .attr("class", "chart-svg")
    .attr("role", "table")
    .attr("viewBox", "0 -5 " + width + " " + height)
    .attr("aria-describedby", descriptionElementId)
    .attr("preserveAspectRatio", "none")
    .classed("svg-content-responsive", true);

  // ADD AXIS
  var xTicks = data[0].values.length - 1;
  var xAxis = d3
    .axisBottom(xScale)
    .ticks(xTicks)
    .tickFormat((x) => x + 1);

  var yAxis = d3.axisLeft(yScale);

  // ADD X AXIS
  var xAxisWrapper = svg
    .append("g")
    .attr("class", "x axis")
    .attr("transform", `translate(${margin / 2}, ${newHeight})`)
    .attr("role", "row");
  xAxisWrapper
    .append("g")
    .attr("role", "columnheader")
    .attr("scope", "col")
    .append("text")
    .text("Themes")
    .attr("class", "sr-only");
  xAxisWrapper.call(xAxis);

  svg.selectAll(".x .tick").attr("role", "columnheader").attr("scope", "col");

  // BACKGROUND SHADING
  svg
    .selectAll("rect")
    .data(d3.pairs(svg.selectAll(".x .tick").data())) //Generate Tick pair
    .enter()
    .append("rect")
    .attr("class", "shading")
    .attr("x", (d) => xScale(d[0]) + margin / 2) // Where 1st tick start
    .attr("y", 0)
    .attr("width", (d) => xScale(d[1]) - xScale(d[0])) // Set width to be the diff of 1st and 2nd tick
    .attr("height", newHeight)
    .attr("fill", (d, i) => (i % 2 == 0 ? "white" : "#f8f7f5"));

  // ADD Y AXIS
  svg.append("g").attr("class", "y axis").call(yAxis);

  // Y-AXIS LEGEND
  var yLowerLegend = svg
    .select(".y")
    .append("text")
    .attr("y", height - margin / 2)
    .attr("x", margin + 25)
    .attr("fill", "#000");

  var yUpperLegend = svg
    .select(".y")
    .append("text")
    .attr("y", 10)
    .attr("x", margin + 25)
    .attr("fill", "#000");

  // ADD GRID LINES
  // gridlines in x axis function
  function make_x_gridlines() {
    return d3.axisBottom(xScale).ticks(data[0].values.length);
  }

  // gridlines in y axis function
  function make_y_gridlines() {
    return d3.axisLeft(yScale).ticks(2);
  }

  // Y GRID LINES
  // TOP LINE
  svg
    .append("line")
    .attr("x1", margin / 2)
    .attr("y1", 0)
    .attr("x2", width - margin / 2)
    .attr("y2", 0)
    .attr("class", "grid")
    .style("stroke-dasharray", "6, 6")
    .style("stroke-width", 1)
    .style("stroke", "#003032")
    .style("fill", "none");

  // MID LINE
  svg
    .append("line")
    .attr("x1", margin / 2)
    .attr("y1", newHeight / 2)
    .attr("x2", width - margin / 2)
    .attr("y2", newHeight / 2)
    .attr("class", "grid")
    .style("stroke-dasharray", "6, 6")
    .style("stroke-width", 1)
    .style("stroke", "#003032")
    .style("fill", "none");

  // BOTTOM LINE
  svg
    .append("line")
    .attr("x1", margin / 2)
    .attr("y1", newHeight)
    .attr("x2", width - margin / 2)
    .attr("y2", newHeight)
    .attr("class", "grid")
    .style("stroke-dasharray", "6, 6")
    .style("stroke-width", 1)
    .style("stroke", "#003032")
    .style("fill", "none");

  // PLOTTED LINES
  let lines = svg.append("g").attr("class", "lines");

  lines
    .selectAll(".line-group")
    .data(data)
    .enter()
    .append("g")
    .attr("class", "line-group")
    .append("path")
    .attr("class", "line")
    .attr("d", (d) => line(d.values))
    .style("stroke", (d, i) => d.color)
    .attr("data-legend", (d) => d.values)
    .on("mouseover", function (d) {
      d3.selectAll(".line").style("opacity", otherLinesOpacityHover);
      d3.selectAll(".circle").style("opacity", circleOpacityOnLineHover);
      d3.select(this)
        .style("opacity", lineOpacityHover)
        .style("stroke-width", lineStrokeHover)
        .style("cursor", "pointer");
    })
    .on("mouseout", function (d) {
      d3.selectAll(".line").style("opacity", lineOpacity);
      d3.selectAll(".circle").style("opacity", circleOpacity);
      d3.select(this).style("stroke-width", lineStroke).style("cursor", "none");
    });

  // PLOTTED CIRCLES OR DIAMOND
  let themeRow = lines
    .selectAll(".point-group")
    .data(data)
    .enter()
    .append("g")
    .attr("role", "row")
    .style("fill", (d, i) => d.color);

  themeRow
    .append("text")
    .attr("role", "rowheader")
    .attr("scope", "row")
    .attr("class", "sr-only")
    .text((d) => d.theme);

  themeRow
    .selectAll(".point-group")
    .data((d) => d.values)
    .enter()
    .append("text")
    .attr(
      "transform",
      (d) => "translate(" + xScale(d.date) + "," + yScale(d.score) + ")"
    )
    .attr("role", "cell")
    .attr("style", "font-size: 1px")
    .attr("class", "sr-only")
    .text((d) => d.description);

  themeRow
    .selectAll(".point-group")
    .data((d) => d.values)
    .enter()
    .append("path")
    .attr(
      "d",
      d3.symbol().type((d) => {
        if (d.labels.length > 1) {
          return d3.symbolSquare;
        } else {
          return d3.symbolCircle;
        }
      })
    )
    .attr("fill", (d) => {
      if (d.labels.length > 1) {
        return "#5ac0e8";
      }
    })
    .on("mouseover", function (d) {
      d3.select(this).style("cursor", "pointer");
    })
    .attr(
      "transform",
      (d) => "translate(" + xScale(d.date) + "," + yScale(d.score) + ")"
    )
    .style("opacity", circleOpacity)
    .style("opacity", (d) => {
      if (d.score == null) {
        return 0.0;
      }
    })
    .on("mouseover", function (d) {
      if (d.score == null) {
        return;
      }
      tooltipVisible(d3.event);
      d3.select(this)
        .transition()
        .duration(duration)
        .attr("r", circleRadiusHover);
      let label_string = "";
      let ariaString = "";
      d.labels.forEach((catLabel, i) => {
        let labels = d.labels.length;

        label_string += `<li>${catLabel}</li>`;
        if (labels == i + 1) {
          ariaString += catLabel;
        } else {
          ariaString += `${catLabel}, `;
        }
      });
      vizToolTip.style("opacity", 0.9).style("display", "block");
      label_string = "";
      d.labels.forEach((d) => {
        label_string += "<li>" + d + "</li>";
      });
      vizToolTip
        .html(`<ul>${label_string}</ul>`)
        .style("display", "block")
        .attr("aria-label", `${ariaString}`)
        .attr("aria-hidden", "false")
        .html(`<ul>${label_string}</ul>`);
    })
    .on("mouseout", function (d) {
      d3.select(this).transition().duration(duration).attr("r", circleRadius);
      vizToolTip.attr("aria-hidden", "true");
      tooltipHidden();
    });

  lines.attr("transform", `translate(${margin / 2},0)`);
}

export function generateLegends(data, selector) {
  const selectorNotEmpty = !$(selector).is(":empty");
  if (!data || selectorNotEmpty) {
    return;
  }

  data.forEach(function (theme, idx) {
    if (idx == data.length - 1) {
      let temp_div = jQuery("<div/>", {
        class: "key-item key-overlap",
      });
      temp_div.appendTo(selector);
      jQuery("<div/>", {
        class: "key-color " + "overlap",
        css: {
          "background-color": theme.color,
        },
      }).appendTo(temp_div);
      let key_label = jQuery("<div/>", {
        class: "key-label",
        text: theme.theme.split(",")[0],
      }).appendTo(temp_div);
      jQuery("<span/>", {
        text: theme.theme.split(",")[1],
      }).appendTo(key_label);
    } else {
      let temp_div = jQuery("<div/>", {
        class: "key-item",
      });
      temp_div.appendTo(selector);
      jQuery("<div/>", {
        class: "key-color " + theme.theme.replace(" ", "-").toLowerCase(),
        css: {
          "background-color": theme.color,
        },
      }).appendTo(temp_div);
      jQuery("<div/>", {
        class: "key-label",
        text: theme.theme,
      }).appendTo(temp_div);
    }
  });
}

function displayDelta(theme, container, content) {
  if (theme) {
    let deltaMainContainer = container.parent(".category-changes");

    deltaMainContainer.removeClass("hidden");
    container.removeClass("hidden");
    content.text(theme);
  }
}

$(document).ready(function () {
  var dataUrl = $(UNDRAWN_GRAPH_SELECTOR).attr("data-src");
  $.ajax({
    type: "GET",
    url: dataUrl,
    success: function (response) {
      if (response.entity == "COHORT") {
        initGraph(
          response.data,
          response.summary,
          $(UNDRAWN_GRAPH_SELECTOR)[0]
        );
        generateLegends(response.color_map, $(UNDRAWN_LEGENDS_SELECTOR));
        displayDelta(
          response.max_increase_theme,
          $(MAX_INCREASE_SELECTOR),
          $(MAX_INCREASE_CONTENT_SELECTOR)
        );
        displayDelta(
          response.max_decrease_theme,
          $(MAX_DECREASE_SELECTOR),
          $(MAX_DECREASE_CONTENT_SELECTOR)
        );
      }
    },
    error: function (result) {
      console.error("Error loading graph");
    },
  });
});
