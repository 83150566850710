import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "audit-info" }
const _hoisted_2 = { class: "audit-details" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
      return (_openBlock(), _createElementBlock("li", {
        key: error.message,
        class: _normalizeClass(_ctx.errorClassName(error))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["audit-icon", _ctx.errorClassName(error)])
          }, null, 2),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", _hoisted_3, [
              _createElementVNode("b", null, _toDisplayString(_ctx.errorLabel(error)), 1)
            ]),
            _createElementVNode("p", _hoisted_4, [
              _createElementVNode("i", null, [
                _createTextVNode(_toDisplayString(error.message) + " ", 1),
                (error.instance)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode("(" + _toDisplayString(_ctx.errorDateRange(error)) + ")", 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ])
            ]),
            (error.instance)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  class: "btn",
                  onClick: ($event: any) => (_ctx.handleClick(error))
                }, " Jump-to ", 8, _hoisted_5))
              : _createCommentVNode("", true)
          ])
        ])
      ], 2))
    }), 128))
  ]))
}