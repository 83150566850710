/* eslint-disable no-redeclare */

export function addTooltipContainer() {
  $(".tool-tip").parent().not("body").addClass("tooltip-container");
}

export function initializeTooltip() {
  $("body").on("mouseenter touchstart", ".tooltip-container", tooltipVisible);
  $("body").on("keyup", function (event) {
    tooltipHidden();

    if (
      $(this).hasClass("tooltip-container") ||
      $(event.target).hasClass("tooltip-container")
    ) {
      tooltipVisible(event);
    }
  });
  $("body").on("mouseleave", ".tooltip-container", tooltipHidden);
  $("body").on("touchstart", tooltipHidden);

  $(".info-indicator").on("click", function (e) {
    let thisTooltip = $(this);

    if (thisTooltip.hasClass("open-tip")) {
      thisTooltip.removeClass("open-tip");
      tooltipHidden();
    } else {
      thisTooltip.addClass("open-tip");
      tooltipVisible(e);
    }
  });
}

export function tooltipVisible(event) {
  tooltipHidden();

  if (
    $(this).hasClass("tooltip-container") ||
    $(event.target).hasClass("tooltip-container")
  ) {
    var thisToolTipReference = "";
    var thisToolTipContainer = "";

    if ($(this).length) {
      thisToolTipContainer = $(this);
      thisToolTipReference = $(this).find(".tool-tip");
    } else {
      thisToolTipContainer = $(event.target);
      thisToolTipReference = $(event.target).find(".tool-tip");
    }

    var toolTipText = thisToolTipReference.html();

    if (thisToolTipReference.length == 0) {
      return;
    }

    var containerWidth = thisToolTipContainer.outerWidth();
    var containerHeight = thisToolTipContainer.outerHeight();

    $("body").append(
      `<div class="tool-tip active-tooltip">${toolTipText}</div>`
    );
    var activeTempToolTip = $(".active-tooltip").not(".visualization-tooltip");

    if (thisToolTipContainer.hasClass("member-card")) {
      var spacing = -17;
    } else {
      var spacing = 4;
    }

    if (thisToolTipContainer.hasClass("category-tooltip")) {
      activeTempToolTip.addClass("category-tooltip");
    } else if (thisToolTipContainer.hasClass("small-tooltip")) {
      activeTempToolTip.addClass("small-tooltip");
    }

    var toolTipWidth = activeTempToolTip.outerWidth();
    var toolTipHeight = activeTempToolTip.outerHeight();

    var leftOffset = thisToolTipContainer.offset().left;
    var leftPos = leftOffset + containerWidth + spacing;
    var leftRightAlignedPos = leftOffset - toolTipWidth - spacing;

    var topOffset = thisToolTipContainer.offset().top;
    var topPos = topOffset;
    var topCenteredPos = topPos + containerHeight + spacing;

    var bottomOffset = topOffset + toolTipHeight;
    var containerBottomOffset = topOffset + containerHeight;
  } else {
    var activeTempToolTip = $(".active-tooltip");

    var spacing = 5;

    var leftPos = event.pageX + spacing;
    var leftRightAlignedPos = event.pageX - spacing;

    var topPos = event.pageY;
  }

  var toolTipRightEdge = leftPos + toolTipWidth;

  if (toolTipRightEdge > $(window).width()) {
    activeTempToolTip.addClass("right-align");
    activeTempToolTip.css({ left: leftRightAlignedPos, top: topPos });

    if (
      leftRightAlignedPos < 0 &&
      !activeTempToolTip.hasClass("visualization-tooltip")
    ) {
      var margin = 64;
      var toolTipWidth = $(window).width() - margin;

      activeTempToolTip
        .removeClass("right-align")
        .addClass("center-align")
        .css({ left: 0, width: toolTipWidth, top: topCenteredPos });
    }
  } else {
    activeTempToolTip.css({ left: leftPos, top: topPos });
  }

  if (
    bottomOffset > $(window).height() &&
    containerBottomOffset - toolTipHeight > 0 &&
    !activeTempToolTip.hasClass("center-align")
  ) {
    var bottomAlignOffset = topPos - toolTipHeight + containerHeight;

    activeTempToolTip.addClass("bottom-align").css({ top: bottomAlignOffset });
  }

  $(window).on("scroll", tooltipHidden);
  $(".modal-main").on("scroll", tooltipHidden);
}

export function tooltipHidden() {
  $(".active-tooltip").not(".visualization-tooltip").remove();
  $(".visualization-tooltip")
    .removeClass("right-align")
    .removeClass("center-align")
    .removeAttr("style")
    .css({ opacity: 0 });
}
