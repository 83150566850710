<template>
    <SlickList
      :list="availableTasks"
      axis="y"
      lock-axis="y"
      @update:list="onDragUpdate"
      class="multi-select-container"
    >
      <SlickItem
        v-for="(task, i) in availableTasks"
        :key="task.num"
        :index="i"
        class="select-item-container"
      >
        <TaskInputRow
          :value="task.value"
          :placeholder="task.placeholder"
          @input="onUpdateValue($event, task.num)"
          @next-item="onNextItem(task.num)"
          @remove-row="onRemoveRow(task.num)"
          @paste="onPaste($event, task.num)"
          ref="rows"
        />
      </SlickItem>
    </SlickList>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { SlickList, SlickItem } from "vue-slicksort";

import TaskInputRow from "./TaskInputRow.vue";

export default defineComponent({
  components: { SlickItem, SlickList, TaskInputRow },
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    placeholders: {
      type: Array,
      default: () => [],
    },
    minNumValues: {
      type: Number,
      default: 1,
    },
    maxNumValues: {
      type: Number,
      default: 8,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const rows = ref();
    const numValues = computed(() =>
      Math.max(
        props.minNumValues,
        Math.min(props.maxNumValues, props.modelValue.length + 1)
      )
    );
    const availableTasks = computed(() => {
      const range = [...new Array(numValues.value).keys()];
      return range.map((i) => ({
        num: i + 1,
        value: props.modelValue[i] || "",
        placeholder: props.placeholders[i] || null,
      }));
    });

    const onNextItem = (taskNum) => {
      const rowsValue = rows.value;
      if (taskNum == rowsValue.length) {
        return;
      }
      const nextRow = rowsValue[taskNum];
      const input = nextRow.$el.querySelector("input");
      input.focus();
    };

    const onUpdateValue = (event, index) => {
      const value = event.target.value;
      const newValues = new Array(...props.modelValue);
      newValues[index - 1] = value;
      emit("update:modelValue", newValues);
    };

    const onRemoveRow = (taskNum) => {
      const newValues = props.modelValue.filter((v, i) => i + 1 != taskNum);
      emit("update:modelValue", newValues);
    };

    const onDragUpdate = (list) => {
      const newValues = list.map((t) => t.value);
      const lastNonEmptyIndex = newValues.reduce(
        (acc, val, index) => (val != "" ? index : acc),
        0
      );
      const sliced = newValues.slice(
        0,
        Math.min(lastNonEmptyIndex, props.maxNumValues) + 1
      );
      emit("update:modelValue", sliced);
    };

    const insertPastedValueIntoArray = (pastedValue, arr, index, maxLength) => {
      const effectiveMaxLength = maxLength - index;
      const arrayToScan = arr.slice(index);
      const numExistingValues = arrayToScan.slice(1).filter((v) => !!v).length;
      let newLines = pastedValue.split("\n");
      const requiredSpace = newLines.length;
      const availableSpace = effectiveMaxLength - numExistingValues;
      const linesAllowed = Math.min(requiredSpace, availableSpace);
      if (linesAllowed < requiredSpace) {
        const head = newLines.slice(0, linesAllowed - 1);
        const tail = newLines.slice(linesAllowed - 1).join(" ");
        newLines = [...head, tail];
      }
      const displacedValues = [];
      const newArr = [];
      for (let i = 0; i < maxLength; i++) {
        const currentValue = arr[i] || "";
        const newLinesIndex = i - index;

        if (i < index) {
          newArr[i] = currentValue;
          continue;
        }

        if (i > index && currentValue) {
          displacedValues.push(currentValue);
        }

        if (newLinesIndex < newLines.length) {
          newArr[i] = newLines[newLinesIndex];
        } else if (displacedValues.length > 0) {
          newArr[i] = displacedValues.shift();
        }
      }

      return newArr;
    };

    const onPaste = (text, taskNum) => {
      const newArray = insertPastedValueIntoArray(
        text.trim(),
        props.modelValue,
        taskNum - 1,
        props.maxNumValues
      );
      emit("update:modelValue", newArray);
    };

    return {
      rows,
      numValues,
      availableTasks,
      onDragUpdate,
      onNextItem,
      onPaste,
      onRemoveRow,
      onUpdateValue,
    };
  },
});
</script>
