
import { PropType, defineComponent } from "vue";
import { DateTime } from "luxon";
import PlannerEventCard from "@/components/PlannerEventCard.vue";
import TandemDateInput from "@/components/TandemDateInput.vue";
import {
  CalendarEvent,
  EventUpdateData,
  Assignment,
  EventTypeCategory,
  SurveyType,
} from "@/core";

export default defineComponent({
  props: {
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
    assignment: {
      type: Object as PropType<Assignment>,
      required: true,
    },
  },
  components: {
    TandemDateInput,
    PlannerEventCard,
  },
  computed: {
    EventTypeCategory: () => EventTypeCategory,
    SurveyType: () => SurveyType,
    originalDueDate(): DateTime {
      return this.calendarEvent.extendedProps.element.publishDate;
    },
    isLesson(): boolean {
      return (
        this.assignment.type === EventTypeCategory.Lesson ||
        this.assignment.type === EventTypeCategory.Reflection
      );
    },
  },
  data() {
    const { dueDate } = this.calendarEvent.extendedProps.element;
    return {
      dueDate,
    };
  },
  methods: {
    handleSubmit(event: Event) {
      event.preventDefault();
      const eventData: EventUpdateData = {
        dueDate: this.dueDate,
      };
      this.$emit("submit", eventData);
    },
  },
});
