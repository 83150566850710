import {
  TEAM_GRAPH_SELECTOR,
  initGraph,
  generateLegends,
} from "@/scripts/performance-viz.js";
import { addTooltipContainer } from "@/scripts/tool-tip.js";

export const renderPerformanceGraph = () => {
  const dataUrl = $(TEAM_GRAPH_SELECTOR).attr("data-src");
  $.ajax({
    type: "GET",
    url: dataUrl,
    success: function (response) {
      initGraph(response.data, response.summary, $(TEAM_GRAPH_SELECTOR)[0]);
      generateLegends(response.color_map, $(".team-graph .graph-key"));
    },
    error: function () {
      console.error("Error loading graph");
    },
  });
  addTooltipContainer();
};
