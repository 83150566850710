import { INDIVIDUAL_GRAPH_SELECTOR } from "@/scripts/performance-viz.js";
import { generateLegends, initGraph } from "@/scripts/performance-viz.js";
import { addTooltipContainer } from "@/scripts/tool-tip.js";

export const renderIndividualGraph = () => {
  var dataUrl = $(INDIVIDUAL_GRAPH_SELECTOR).attr("data-src");
  $.ajax({
    type: "GET",
    url: dataUrl,
    success: function (response) {
      initGraph(
        response.data,
        response.summary,
        $(INDIVIDUAL_GRAPH_SELECTOR)[0]
      );
      generateLegends(response.color_map, $(".individual-graph .graph-key"));
    },
    error: function () {
      console.error("Error loading graph");
    },
  });
  addTooltipContainer();
};
