$(document).ready(function () {
  initializeTableOrdering();
});

export function initializeTableOrdering() {
  var tables = $(".tableConnected");

  if (tables.length > 0) {
    tables.each(orderTableItems);
  }
}

function orderTableItems() {
  var tableItems = $(this).children();
  var itemCount = tableItems.length;

  if ($(this).hasClass("two-column")) {
    for (let i = 0; i < itemCount / 2; i++) {
      $(tableItems[i]).css({ order: i, "-webkit-order": i, "-ms-order": i });
      $(tableItems[itemCount / 2 + i])
        .css({ order: i, "-webkit-order": i, "-ms-order": i })
        .addClass("right-item");

      if (i == itemCount / 2 - 1) {
        $(tableItems[i]).addClass("bottom-item");
        $(tableItems[itemCount / 2 + i]).addClass("bottom-item");
      }
    }
  } else if ($(this).hasClass("three-column")) {
    for (let i = 0; i < itemCount / 3; i++) {
      $(tableItems[i]).css({ order: i, "-webkit-order": i, "-ms-order": i });
      $(tableItems[itemCount / 3 + i]).css({
        order: i,
        "-webkit-order": i,
        "-ms-order": i,
      });
      $(tableItems[(itemCount / 3) * 2 + i])
        .css({ order: i, "-webkit-order": i, "-ms-order": i })
        .addClass("right-item");

      if (i == itemCount / 3 - 1) {
        $(tableItems[i]).addClass("bottom-item");
        $(tableItems[itemCount / 3 + i]).addClass("bottom-item");
        $(tableItems[(itemCount / 3) * 2 + i]).addClass("bottom-item");
      }
    }
  }
}
