import { DateTime } from "luxon";
import apiClient from "./client";

interface SessionExtensionAPIResponse {
  new_time: string;
}

export async function extendSession(): Promise<DateTime> {
  try {
    const response = await apiClient.post<SessionExtensionAPIResponse>(
      "/api/extend-session/"
    );
    const newTimeISO = response.data.new_time;
    return DateTime.fromISO(newTimeISO);
  } catch {
    return DateTime.invalid("No longer active");
  }
}
