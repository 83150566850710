<template>
  <vue-final-modal
    v-bind="$attrs"
    classes="vfm-modal-container"
    content-class="vfm-modal-content"
    v-slot="{ close }"
  >
    <div class="header-block">
      <slot name="title" />
    </div>
    <div class="modal-directions">
      <slot name="directions" />
    </div>
    <div class="button-block modal-buttons">
      <slot
        name="buttons"
        v-bind="{ close }"
      >
        <button
          type="button"
          class="btn btn-burg"
          @click="close"
        >
          Close
        </button>
      </slot>
    </div>
  </vue-final-modal>
</template>
