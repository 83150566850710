import apiClient from "@/services/client";
import { ScheduleElement, APIScheduleElement, toScheduleElement } from "@/core";

export const getCalendarScheduleElements = async (
  cohortId: string
): Promise<ScheduleElement[]> => {
  const { data } = await apiClient.get<APIScheduleElement[]>(
    `/planner/api/cohort/${cohortId}/schedule-elements`
  );
  return data.map((element) => toScheduleElement(element));
};
