<template>
  <div class="faq-item">
    <button
      class="ui-accordion-header"
      @click.prevent="handleClick($event, faq.id)"
      type="button"
    >
      {{ faq.questionText }}
      <span
        v-if="expanded"
        class="ui-accordion-header-icon open-faq"
      />
      <span
        v-else
        class="ui-accordion-header-icon"
      />
    </button>
    <div
      v-if="expanded"
      class="accordion-content"
      v-html="faq.answerText"
    />
  </div>
</template>

<script>
export default {
  props: {
    expanded: Boolean,
    faq: {
      type: Object,
      required: true,
    },
    handleClick: {
      type: Function,
      required: true,
    },
  },
};
</script>
