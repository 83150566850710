/*
 * Use this file for any functionality that is not linked to a specific page,
 * view or widget. For anything else, create a new file named after the specific
 * control and put your code there.
 */

export function record_event(name, note, uid) {
  $.ajax({
    url: get_url("record-event"),
    datatype: "html",
    data: { name: name, note: note, uid: uid },
    success: function () {},
    error: function (data) {
      console.log(data);
    },
  });
}

function get_url(urlName) {
  let url = $("body").data(urlName);
  if (url) {
    return url;
  }
  return null;
}

function getCookie(name) {
  var cookieValue = null;
  if (document.cookie && document.cookie != "") {
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) == name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
var csrftoken = getCookie("csrftoken");
function csrfSafeMethod(method) {
  // these HTTP methods do not require CSRF protection
  return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}
$.ajaxSetup({
  crossDomain: false, // obviates need for sameOrigin test
  beforeSend: function (xhr, settings) {
    if (!csrfSafeMethod(settings.type)) {
      xhr.setRequestHeader("X-CSRFToken", csrftoken);
    }
  },
});
