/*

  Creates and removes a temporary div element with an aria-live attribute,
  which causes the text argument to be read aloud by a screen reader.

  announce(text, priority)
  text: the message to be vocalised
  priority (non mandatory): "polite" (by default) or "assertive"

*/

export function announce(text, priority = "polite") {
  let el = document.createElement("div");
  const id = "announce-" + Date.now();
  el.setAttribute("id", id);
  el.setAttribute("aria-live", priority);
  el.classList.add("sr-only");
  document.body.appendChild(el);

  window.setTimeout(function () {
    document.getElementById(id).innerHTML = text;
  }, 100);

  window.setTimeout(function () {
    document.body.removeChild(document.getElementById(id));
  }, 1000);
}

export function appendChartDescription(
  parentSelector,
  descriptionElId = "",
  description
) {
  let descriptionEl = document.createElement("p");
  descriptionEl.classList.add("sr-only");
  descriptionEl.id = descriptionElId;
  descriptionEl.textContent = description;
  $(parentSelector).append(descriptionEl);
}

export function addPlotlyAriaAttributes(parentSelector, descriptionElId) {
  $(parentSelector + " .main-svg")
    .first()
    .attr("aria-labelledby", descriptionElId);
  $(parentSelector + " .main-svg")
    .first()
    .attr("role", "img");
  $(parentSelector + " .main-svg:not(:first)").attr("aria-hidden", "true");
}
