import apiClient from "@/services/client";

export type QuestionType = "single" | "multiple";

export interface APIResponseOption {
  id: number;
  order: number;
  value: string;
  label: string;
}

export interface APICustomQuestion {
  id: number;
  question_type: QuestionType;
  question_text: string;
  order: number;
  name: string;
  response_options: APIResponseOption[];
}

export interface APIResponseOptionPayload {
  value: string;
}

export interface APICustomQuestionPayload {
  id?: number;
  question_type: QuestionType;
  question_text: string;
  response_options: APIResponseOptionPayload[];
}

export const getCustomQuestions = async (
  provisionalCohortId: number
): Promise<APICustomQuestion[]> => {
  const { data } = await apiClient.get<APICustomQuestion[]>(
    `/console/api/provisional-cohorts/${provisionalCohortId}/custom-questions`
  );
  return data;
};

export const createCustomQuestions = async (
  provisionalCohortId: number,
  payload: APICustomQuestionPayload[]
): Promise<APICustomQuestion[]> => {
  const { data } = await apiClient.post<APICustomQuestion[]>(
    `/console/api/provisional-cohorts/${provisionalCohortId}/custom-questions/bulk_upsert/`,
    payload
  );
  return data;
};
