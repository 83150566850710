
import InfoCard from "@/components/InfoCard.vue";
import { getAssignments, Assignment } from "@/services/assignmentService";
import { defineComponent } from "vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import { DateTime } from "luxon";
import TandemDateInput from "@/components/TandemDateInput.vue";
import TandemCheckbox from "@/components/TandemCheckbox.vue";
interface State {
  assignments: Assignment[];
  selectedTypes: string[];
  loading: boolean;
  before: DateTime;
  after: DateTime;
}
export default defineComponent({
  components: {
    InfoCard,
    LoadingIndicator,
    TandemDateInput,
    TandemCheckbox,
  },
  data(): State {
    return {
      assignments: [],
      selectedTypes: [],
      loading: true,
      before: this.getDefaultRangeEnd(),
      after: this.getDefaultRangeStart(),
    };
  },
  methods: {
    isValidBeforeDate(assignment: Assignment): boolean {
      return (
        !!this.before &&
        (assignment.publishDate < this.before ||
          assignment.dueDate < this.before)
      );
    },
    isValidAfterDate(assignment: Assignment): boolean {
      return (
        !!this.after &&
        (assignment.publishDate > this.after || assignment.dueDate > this.after)
      );
    },
    isValidType(assignment: Assignment): boolean {
      return (
        !this.selectedTypes.length ||
        this.selectedTypes.includes(assignment.assignmentType)
      );
    },
    getDefaultRangeStart(): DateTime {
      return DateTime.now().minus({ weeks: 1 });
    },
    getDefaultRangeEnd(): DateTime {
      return DateTime.now().plus({ weeks: 1 });
    },
    sortAssignments(a: Assignment, b: Assignment) {
      return b.publishDate.toMillis() - a.publishDate.toMillis();
    },
  },
  computed: {
    selectedAssignments(): Assignment[] {
      return this.assignments
        .filter(this.isValidAfterDate)
        .filter(this.isValidBeforeDate)
        .filter(this.isValidType)
        .sort(this.sortAssignments);
    },
  },
  async mounted() {
    const assignments = await getAssignments();
    this.assignments = assignments;
    this.loading = false;
  },
});
