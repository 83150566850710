import {
  getGraphData,
  getMidtermOverallGraphBar,
  getMidtermOverallGraphLayout,
} from "@/scripts/plotly-viz-utils.js";
import {
  appendChartDescription,
  addPlotlyAriaAttributes,
} from "@/scripts/accessibility-helpers.js";

export function evalOverviewGraph() {
  const SELECTOR = "#evalGraphOverview";
  const DESCRIPTION_ID = "evalGraphOverviewDescription";
  const url = $(SELECTOR).data("url");

  getGraphData(url)
    .then((data) => {
      if (!url) {
        throw "No URL provided for initGraph()";
      }

      var xValue = [
        "VERY NEGATIVE",
        "SOMEWHAT NEGATIVE",
        "NEUTRAL",
        "SOMEWHAT POSITIVE",
        "VERY POSITIVE",
      ];
      var graphBar = getMidtermOverallGraphBar();
      var layout = getMidtermOverallGraphLayout();
      var barList = [];

      graphBar.x = xValue;
      graphBar.y = data.aValues;
      graphBar.text = data.aValues.map((val) => `${val}`);
      graphBar.textposition = "outside";

      barList = [graphBar];

      Plotly.newPlot(SELECTOR.substring(1), barList, layout, {
        displayModeBar: false,
        responsive: true,
      });

      appendChartDescription(SELECTOR, DESCRIPTION_ID, data.description);
      addPlotlyAriaAttributes(SELECTOR, DESCRIPTION_ID);
    })
    .catch((error) => {
      console.error(error);
    });
}
