import { onMounted, onUnmounted } from "vue";
import type { Ref, ComputedRef } from "vue";

export default function usePreventUnloadWhen(
  condition: Ref<boolean> | ComputedRef<boolean>
) {
  function handleUnload(event: BeforeUnloadEvent) {
    if (condition.value) {
      event.preventDefault();
      event.returnValue = true;
    }
  }
  const register = () => {
    window.addEventListener("beforeunload", handleUnload);
  };
  const clear = () => {
    window.removeEventListener("beforeunload", handleUnload);
  };
  onMounted(register);
  onUnmounted(clear);
  return {
    clear,
  };
}
