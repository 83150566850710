
import { PropType, defineComponent } from "vue";
import { CalendarEvent } from "@/core";

export default defineComponent({
  props: {
    event: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
  },
  computed: {
    published(): boolean {
      return this.event.extendedProps.element.published;
    },
  },
});
