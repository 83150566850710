import { initializeForm } from "@/scripts/faculty/reset-assignment.js";

export function initializeGrantCredit() {
  $("#studentSelect").on("change", ({ target }) => {
    const studentUrl = target.value;
    $("#contentDiv").load(`${studentUrl}?from_overview=1`, initializeForm);
  });
  const modalContainer = $(".modal-container.show");
  const dropdownParent = modalContainer.is(":visible")
    ? modalContainer
    : $(document.body);
  const select2Config = {
    dropdownParent,
    minimumResultsForSearch: 2,
  };
  $("#studentSelect").select2(select2Config).trigger("change");
}
