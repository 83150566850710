<template>
  <div>
    <h2>Frequently Asked Questions</h2>
    <label
      class="sr-only"
      for="searchFAQ"
    >Search FAQs</label>
    <input
      v-if="showSearchField"
      class="search"
      v-model="searchTerm"
      type="text"
      placeholder="Search"
      id="searchFAQ"
    >
    <div
      class="info-card beige no-results"
      v-if="noFaqItems"
    >
      <p>No FAQs have been created for {{ this.organization }}.</p>
    </div>
    <div
      class="info-card beige no-results"
      v-else-if="noFilterMatches"
    >
      <p>No FAQs matched your search.</p>
    </div>
    <div
      class="accordion faq-accordion"
      v-else
    >
      <FaqListItem
        v-for="faq in filteredItems"
        :key="faq.id"
        :faq="faq"
        :expanded="faq.id === selected"
        :handle-click="selectFaq"
      />
    </div>
  </div>
</template>

<script>
import FaqListItem from "@/components/FaqListItem.vue";

export default {
  components: {
    FaqListItem,
  },
  computed: {
    filteredItems() {
      if (!this.searchTerm) {
        return this.items;
      }
      const matchTerm = this.searchTerm.toLowerCase();
      return this.items.filter(
        (faq) =>
          faq.questionText.toLowerCase().includes(matchTerm) ||
          faq.answerText.toLowerCase().includes(matchTerm)
      );
    },
    noFaqItems() {
      return !this.items || this.items.length === 0;
    },
    noFilterMatches() {
      return !this.filteredItems || this.filteredItems.length === 0;
    },
    showSearchField() {
      return this.items.length > 1;
    },
  },
  data() {
    return {
      selected: null,
      searchTerm: "",
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    organization: {
      type: String,
      required: true,
    },
  },
  methods: {
    selectFaq(e, faqId) {
      this.selected = faqId === this.selected ? null : faqId;
    },
  },
};
</script>

<style></style>
