export function initEditor(elementId) {
  let title =
    $(`label[for="${elementId}"]`)[0].textContent.trim() || "Rich Text";
  CKEDITOR.replace(elementId, {
    width: "100%",
    height: "auto",
    enterMode: 1,
    toolbar_Custom: [
      { name: "format", items: ["Bold", "Italic", "Underline"] },
      { name: "lists", items: ["BulletedList", "NumberedList"] },
      { name: "links", items: ["Link", "Unlink"] },
    ],
    toolbar: "Custom",
    removeDialogTabs: "link:advanced;link:target",
    disableNativeSpellChecker: false,
    title: title,
  });

  CKEDITOR.on("dialogDefinition", function (e) {
    var dialogName = e.data.name;
    var dialogDefinition = e.data.definition;
    var infoTab = dialogDefinition.getContents("info");

    if (dialogName == "link") {
      infoTab.get("linkType").style = "display: none";
      infoTab.get("protocol").style = "display: none";
    }
  });
}
