import { DateTime } from "luxon";
import { defineStore } from "pinia";
import { min, max } from "lodash";
import {
  createScheduleElement,
  Assignment,
  EventType,
  EventUpdateData,
  EventCreateData,
  toUpdatedScheduleElement,
  toCalendarEvents,
  isAssignableContent,
  SchedulePlan,
  ScheduleError,
  ScheduleElement,
  isAssignmentEvent,
  ErrorLevel,
} from "@/core";
export interface SchedulePlanState {
  eventTypes: EventType[];
  errors: ScheduleError[];
  elements: ScheduleElement[];
}

export const useScheduleStore = defineStore("schedule", {
  state: (): SchedulePlanState => ({
    elements: [],
    eventTypes: [],
    errors: [],
  }),
  getters: {
    calendarEvents: (state) => {
      return toCalendarEvents(state.elements, state.errors);
    },
    assignedContentIds(): Set<string> {
      return new Set(this.elements.map(({ type }) => type.id));
    },
    assignableContent(): Assignment[] {
      return this.eventTypes
        .filter(isAssignmentEvent)
        .filter((content) =>
          isAssignableContent(content, this.assignedContentIds)
        );
    },
    hasErrors: (state) => !!state.errors.length,
    minDateTime: (state): DateTime | undefined =>
      min(state.elements.map((event) => event.publishDate)),
    maxDateTime: (state): DateTime | undefined =>
      max(state.elements.map((event) => event.dueDate)),
    canFinalize: (state) =>
      state.errors.every((error) => error.level !== ErrorLevel.Error),
  },
  actions: {
    applySchedulePlan(schedule: SchedulePlan) {
      this.eventTypes = schedule.eventTypes.concat();
      this.errors = schedule.errors.concat();
      this.elements = schedule.elements.concat();
    },
    syncSchedulePlan(schedule: SchedulePlan) {
      this.eventTypes = schedule.eventTypes;
      this.errors = schedule.errors;
    },
    findScheduleElement(id: string): ScheduleElement | undefined {
      return this.elements.find((element) => element.id === id);
    },
    filterElement(id: string) {
      this.elements = this.elements.filter((element) => element.id !== id);
    },
    createEvent(eventData: EventCreateData) {
      const element = createScheduleElement(eventData);
      this.elements = this.elements.concat(element);
    },
    updateEvent(id: string, properties: EventUpdateData) {
      const element = this.findScheduleElement(id);
      if (element) {
        this.filterElement(id);
        const mergedElement = toUpdatedScheduleElement(element, properties);
        this.elements = this.elements.concat(mergedElement);
      }
    },
    deleteEvent(id: string) {
      this.filterElement(id);
    },
    loadSnapshot(snapshot: SchedulePlanState) {
      this.$patch({ ...snapshot });
    },
  },
});
