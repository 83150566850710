import apiClient from "@/services/client";

export const getTeamEvaluationTeamResultsFragment = async (
  cohortId: number,
  cohortSurveyId: number,
  teamId: number
): Promise<string> => {
  const { data } = await apiClient.get<string>(
    `/api/fragments/cohorts/${cohortId}/teams/${teamId}/cohort-surveys/${cohortSurveyId}`
  );
  return data;
};

export const getTeamEvaluationMemberResultsFragment = async (
  cohortId: number,
  cohortSurveyId: number,
  memberId: number
): Promise<string> => {
  const { data } = await apiClient.get<string>(
    `/api/fragments/cohorts/${cohortId}/members/${memberId}/cohort-surveys/${cohortSurveyId}`
  );
  return data;
};

export const getTeamEvaluationClassResultsFragment = async (
  cohortId: number,
  cohortSurveyId: number
): Promise<string> => {
  const { data } = await apiClient.get<string>(
    `/api/fragments/cohorts/${cohortId}/cohort-surveys/${cohortSurveyId}`
  );
  return data;
};
