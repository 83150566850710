
import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { DateTime } from "luxon";
import type { CalendarOptions } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import PlannerCalendarEvent from "@/components/PlannerCalendarEvent.vue";
import CohortCalendarCard from "@/components/CohortCalendarCard.vue";
import { CalendarEvent, isMilestoneEvent, clampedDateTime } from "@/core";

import { useCalendarStore } from "@/stores/calendar";

interface EventData {
  publishDate: DateTime;
  dueDate: DateTime;
}

const baseCalendarOptions = {
  defaultAllDay: true,
  plugins: [dayGridPlugin],
  editable: false,
  selectable: true,
  droppable: false,
  dayHeaderFormat: {
    weekday: "narrow" as "narrow",
  },
};

export default defineComponent({
  components: {
    FullCalendar,
    PlannerCalendarEvent,
    CohortCalendarCard,
  },
  props: {
    cohortId: {
      type: String,
      required: true,
    },
    showMilestones: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedId: null as string | null,
      newEvent: null as EventData | null,
    };
  },
  computed: {
    ...mapState(useCalendarStore, ["calendarEvents", "firstDate", "lastDate"]),
    showModal: {
      get(): boolean {
        return this.newEvent !== null;
      },
      set(value: any) {
        if (!value) {
          this.newEvent = null;
        }
      },
    },
    initialDate(): DateTime {
      return clampedDateTime(DateTime.now(), this.firstDate, this.lastDate);
    },
    selectedEvent(): CalendarEvent | undefined {
      return this.calendarEvents.find((e) => e.id === this.selectedId);
    },
    filteredEvents() {
      return this.showMilestones
        ? this.calendarEvents
        : this.calendarEvents.filter(
            (event) => !isMilestoneEvent(event.extendedProps.eventType)
          );
    },
    calendarOptions(): CalendarOptions {
      return {
        ...baseCalendarOptions,
        dayCellContent: ({ date }) => date.getDate(),
        initialView: "dayGridWeek",
        editable: false,
        events: this.filteredEvents,
        contentHeight: "auto",
        footerToolbar: {
          right: "dayGridMonth dayGridWeek",
        },
        headerToolbar: {
          right: "today prev,next",
          left: "title",
        },
        initialDate: this.initialDate.toISODate(),
        eventClick: this.handleEventClick,
      };
    },
  },
  methods: {
    ...mapActions(useCalendarStore, ["loadCalendarData"]),
    handleEventClick({ event }: { event: any }) {
      this.selectedId = event.id;
    },
  },
  async created() {
    await this.loadCalendarData(this.cohortId);
  },
});
