import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { sortBy } from "lodash";
import type { DateSpanApi, EventApi } from "@fullcalendar/core";

const TANDEM_DATE_SHORT = "M'.'d'.'yy";
const TANDEM_TIME_WITH_SECONDS = "hh:mm:ssa";
const PUBLISHED_EVENT_CLASS_NAME = "published";
const ACTIVE_EVENT_CLASS_NAME = "active";

type ISODateTimeString = string;
type ISODateString = string;

enum SurveyType {
  Midterm = "Midterm",
  EoT = "EoT",
  BoT = "BoT",
}

enum EventTypeCategory {
  TeamCheck = "teamcheck",
  Lesson = "lesson",
  Survey = "survey",
  Reflection = "reflection",
  Milestone = "milestone",
}

enum ErrorLevel {
  Warning = "WARNING",
  Error = "ERROR",
}

interface APIEventTypeBase {
  id: string;
  name: string;
  deletable: boolean;
  type: string;
}

interface APISingleDayEvent extends APIEventTypeBase {
  description: string;
}

interface APIAssignment extends APIEventTypeBase {
  time_commitment: {
    time: string;
    comment: string | null;
  };
  purpose: string;
  is_system_assigned?: boolean;
  survey_type?: string;
}

type APIEventType = APIAssignment | APISingleDayEvent;

function isAPISingleDayEvent(
  apiEventType: APIEventType
): apiEventType is APISingleDayEvent {
  return (
    (apiEventType.type as EventTypeCategory) === EventTypeCategory.Milestone
  );
}

interface APIEvent {
  id: string;
  publish_date: ISODateTimeString;
  due_date: ISODateTimeString;
  type_id: string;
}

interface APIObjectReference {
  event_id: string;
  publish_date: ISODateTimeString;
  due_date: ISODateTimeString;
  published: boolean;
  active: boolean;
}

interface APIScheduleElement {
  id: string;
  publish_date: ISODateTimeString;
  due_date: ISODateTimeString;
  type: APIEventType;
  reference: APIObjectReference | null;
}

interface APIScheduleError {
  message: string;
  code: string;
  level: string;
  instance: {
    id: string;
    publish_date: ISODateTimeString;
    due_date: ISODateTimeString;
    type: APIEventType;
  };
}

interface APIScheduleAdjustment {
  description: string;
}

interface APIScheduleChanges {
  modifications: APIScheduleAdjustment[];
  extensions: APIScheduleAdjustment[];
  additions: APIScheduleAdjustment[];
  deletions: APIScheduleAdjustment[];
}

interface APISchedulePlan {
  pk: number;
  cohort_id: number;
  is_valid: boolean;
  previous: Number | null;
  events: APIEvent[];
  event_types: APIEventType[];
  schedule_errors: APIScheduleError[];
  schedule_elements: APIScheduleElement[];
  changes: APIScheduleChanges;
}

interface APISchedulePlanCreatePayload {
  events: APIEvent[];
  previous: number;
}

const ContentTypeClassName: { [key in EventTypeCategory]: string } = {
  [EventTypeCategory.TeamCheck]: "team-check",
  [EventTypeCategory.Lesson]: "lesson",
  [EventTypeCategory.Survey]: "team-eval",
  [EventTypeCategory.Reflection]: "lesson",
  [EventTypeCategory.Milestone]: "milestone",
};

const ErrorLevelClassName: { [key in ErrorLevel]: string } = {
  [ErrorLevel.Error]: "critical",
  [ErrorLevel.Warning]: "warning",
};

const SurveyTypeClassName: { [key in SurveyType]: string } = {
  [SurveyType.BoT]: "bot",
  [SurveyType.EoT]: "eot",
  [SurveyType.Midterm]: "mid",
};

interface EventCreateData {
  publishDate: DateTime;
  dueDate: DateTime;
  assignment: Assignment;
}

interface EventUpdateData {
  publishDate?: DateTime;
  dueDate?: DateTime;
  type?: Assignment;
}

interface Event {
  id: string;
  publishDate: DateTime;
  dueDate: DateTime;
  typeId: string;
}

interface EventTypeBase {
  type: EventTypeCategory;
  name: string;
  id: string;
  deletable: boolean;
}

interface Milestone extends EventTypeBase {
  description: string;
}

interface Assignment extends EventTypeBase {
  timeCommitment: {
    time: string;
    comment: string | null;
  };
  purpose: string;
  isSystemAssigned: boolean | null;
  surveyType?: SurveyType;
}

type EventType = Assignment | Milestone;

function isMilestoneEvent(eventType: EventType): eventType is Milestone {
  return eventType.type === EventTypeCategory.Milestone;
}

function isAssignmentEvent(eventType: EventType): eventType is Assignment {
  return eventType.type !== EventTypeCategory.Milestone;
}

interface ScheduleError {
  message: string;
  code: string;
  level: ErrorLevel;
  instance: null | {
    id: string;
    publishDate: DateTime;
    dueDate: DateTime;
    eventType: EventType;
  };
}

interface ScheduleElement {
  id: string;
  publishDate: DateTime;
  dueDate: DateTime;
  type: EventType;
  published: boolean;
  active: boolean;
  deletable: boolean;
}

interface ScheduleAdjustment {
  description: string;
}

interface ScheduleChanges {
  modifications: ScheduleAdjustment[];
  extensions: ScheduleAdjustment[];
  additions: ScheduleAdjustment[];
  deletions: ScheduleAdjustment[];
}

interface SchedulePlan {
  id: number;
  events: Event[];
  eventTypes: EventType[];
  errors: ScheduleError[];
  isValid: boolean;
  elements: ScheduleElement[];
  changes: ScheduleChanges;
}

interface ExtendedProps {
  deletable: boolean;
  eventType: EventType;
  element: ScheduleElement;
}

interface CalendarEvent {
  id: string;
  start: ISODateTimeString;
  end: ISODateString;
  title: string;
  allDay?: boolean;
  editable?: boolean;
  duration?: {
    days: number;
  };
  durationEditable?: boolean;
  classNames?: string[];
  extendedProps: ExtendedProps;
  startEditable: boolean;
}

function toEvent(event: APIEvent): Event {
  return {
    id: event.id,
    publishDate: DateTime.fromISO(event.publish_date),
    dueDate: DateTime.fromISO(event.due_date),
    typeId: event.type_id,
  };
}

function toApiEvent(event: Event): APIEvent {
  return {
    id: event.id,
    publish_date: event.publishDate.toISO(),
    due_date: event.dueDate.toISO(),
    type_id: event.typeId,
  };
}

function scheduleElementToApiEvent(element: ScheduleElement): APIEvent {
  return {
    id: element.id,
    publish_date: element.publishDate.toISO(),
    due_date: element.dueDate.toISO(),
    type_id: element.type.id,
  };
}

function toEventType(apiEventType: APIEventType): EventType {
  const baseEvent: EventTypeBase = {
    id: apiEventType.id,
    name: apiEventType.name,
    deletable: apiEventType.deletable,
    type: apiEventType.type as EventTypeCategory,
  };
  if (isAPISingleDayEvent(apiEventType)) {
    return {
      ...baseEvent,
      description: apiEventType.description,
    };
  } else {
    const assignmentEventType: EventType = {
      ...baseEvent,
      timeCommitment: apiEventType.time_commitment,
      purpose: apiEventType.purpose,
      isSystemAssigned: apiEventType.is_system_assigned || null,
    };
    if (apiEventType.survey_type) {
      assignmentEventType.surveyType = apiEventType.survey_type as SurveyType;
    }
    return assignmentEventType;
  }
}

function toScheduleElement(element: APIScheduleElement): ScheduleElement {
  const type = toEventType(element.type);
  const published = element?.reference?.published === true;
  const active = element?.reference?.active === true;
  const deletable = !published && type.deletable;
  return {
    id: element.id,
    publishDate: DateTime.fromISO(element.publish_date),
    dueDate: DateTime.fromISO(element.due_date),
    type,
    published,
    active,
    deletable,
  };
}

function toScheduleError(error: APIScheduleError): ScheduleError {
  let instance = null;
  if (error.instance) {
    instance = {
      id: error.instance.id,
      publishDate: DateTime.fromISO(error.instance.publish_date),
      dueDate: DateTime.fromISO(error.instance.due_date),
      eventType: toEventType(error.instance.type),
    };
  }
  return {
    message: error.message,
    code: error.code,
    level: error.level as ErrorLevel,
    instance: instance,
  };
}

function toScheduleAdjustment(
  APIScheduleAdjustment: APIScheduleAdjustment
): ScheduleAdjustment {
  return { ...APIScheduleAdjustment };
}

function toScheduleChanges({
  modifications,
  deletions,
  additions,
  extensions,
}: APIScheduleChanges) {
  return {
    modifications: modifications.map(toScheduleAdjustment),
    deletions: deletions.map(toScheduleAdjustment),
    additions: additions.map(toScheduleAdjustment),
    extensions: extensions.map(toScheduleAdjustment),
  };
}

function toApiSchedulePlanCreatePayload(
  previousPlanId: number,
  elements: ScheduleElement[]
): APISchedulePlanCreatePayload {
  return {
    previous: previousPlanId,
    events: elements.map(scheduleElementToApiEvent),
  };
}

function toSchedulePlan(plan: APISchedulePlan): SchedulePlan {
  const eventTypes = plan.event_types.map(toEventType);
  const events = plan.events.map(toEvent);
  const errors = plan.schedule_errors.map(toScheduleError);
  const elements = plan.schedule_elements.map(toScheduleElement);
  const changes = toScheduleChanges(plan.changes);
  return {
    id: plan.pk,
    eventTypes,
    events,
    errors,
    elements,
    changes,
    isValid: plan.is_valid,
  };
}

function getClassNames(
  eventType: EventType,
  element: ScheduleElement,
  errors: ScheduleError[]
): string[] {
  const classNames = errors.map((error) => ErrorLevelClassName[error.level]);
  classNames.push(ContentTypeClassName[eventType.type]);
  if (element.published) {
    classNames.push(PUBLISHED_EVENT_CLASS_NAME);
  }
  if (element.active) {
    classNames.push(ACTIVE_EVENT_CLASS_NAME);
  }
  if (isAssignmentEvent(eventType) && eventType.surveyType) {
    classNames.push(SurveyTypeClassName[eventType.surveyType]);
  }
  return classNames;
}

function toCalendarEvent(
  element: ScheduleElement,
  errors: ScheduleError[]
): CalendarEvent {
  const eventType = element.type;
  const classNames = getClassNames(eventType, element, errors);
  const durationEditable = !isMilestoneEvent(eventType);
  return {
    id: element.id,
    start: element.publishDate.toISODate(),
    end: element.dueDate.plus({ days: 1 }).toISODate(),
    durationEditable: durationEditable,
    title: eventType.name,
    classNames,
    extendedProps: {
      eventType,
      element,
      deletable: element.deletable,
    },
    startEditable: !element.published,
  };
}

function toCalendarEvents(
  elements: ScheduleElement[],
  errorList: ScheduleError[]
): CalendarEvent[] {
  const calendarEvents = [];
  for (const element of elements) {
    const eventType = element.type;
    const errors = errorList.filter(
      (e) => e.instance && e.instance.id === element.id
    );
    if (eventType) {
      calendarEvents.push(toCalendarEvent(element, errors));
    }
  }
  return calendarEvents;
}

function createScheduleElement(eventData: EventCreateData): ScheduleElement {
  return {
    id: uuidv4(),
    type: eventData.assignment,
    publishDate: eventData.publishDate,
    dueDate: eventData.dueDate,
    published: false,
    active: false,
    deletable: true,
  };
}

function parseFullCalendarPublishDate(span: DateSpanApi | EventApi): DateTime {
  return DateTime.fromISO(span.startStr).set({ hour: 6 });
}

function parseFullCalendarDueDate(span: DateSpanApi | EventApi): DateTime {
  return DateTime.fromISO(span.endStr).minus({ days: 1 }).set({
    hour: 23,
    minute: 59,
  });
}

function parseFullCalendarDates(fullCalendarEvent: DateSpanApi | EventApi): {
  publishDate: DateTime;
  dueDate: DateTime;
} {
  return {
    publishDate: parseFullCalendarPublishDate(fullCalendarEvent),
    dueDate: parseFullCalendarDueDate(fullCalendarEvent),
  };
}

function toEventUpdateData(calendarEvent: EventApi): EventUpdateData {
  return {
    publishDate: parseFullCalendarPublishDate(calendarEvent),
    dueDate: parseFullCalendarDueDate(calendarEvent),
  };
}

function toUpdatedScheduleElement(
  element: ScheduleElement,
  updates: EventUpdateData
): ScheduleElement {
  return {
    ...element,
    ...updates,
  };
}

function isAssignableContent(content: Assignment, assignedIds: Set<string>) {
  if (content.type === EventTypeCategory.TeamCheck) {
    return true;
  } else if (content.isSystemAssigned) {
    return true;
  } else {
    return !assignedIds.has(content.id);
  }
}

function parsePublishDateTime(sqlDateString: string): DateTime {
  return DateTime.fromISO(sqlDateString).set({ hour: 6, minute: 0 });
}

function parseDueDateTime(sqlDateString: string): DateTime {
  return DateTime.fromISO(sqlDateString).endOf("day");
}

function areEqualElements(a: ScheduleElement, b: ScheduleElement): boolean {
  return (
    a.id === b.id &&
    a.type.id === b.type.id &&
    +a.publishDate === +b.publishDate &&
    +a.dueDate === +b.dueDate
  );
}

function elementsChanged(
  newElements: ScheduleElement[],
  oldElements: ScheduleElement[]
) {
  if (newElements.length !== oldElements.length) return true;
  newElements = sortBy(newElements, ["id"]);
  oldElements = sortBy(oldElements, ["id"]);
  return newElements.some(
    (element, index) => !areEqualElements(element, oldElements[index])
  );
}

function inDateTimeRange(
  dt: DateTime,
  min: DateTime | null,
  max: DateTime | null
): boolean {
  return (!min || min <= dt) && (!max || dt <= max);
}

function clampedDateTime(
  dt: DateTime,
  min?: DateTime,
  max?: DateTime
): DateTime {
  const lower = min ? DateTime.max(dt, min) : dt;
  return max ? DateTime.min(max, lower) : lower;
}

function categoryLabel(category: EventTypeCategory) {
  return {
    [EventTypeCategory.Lesson]: "Lessons",
    [EventTypeCategory.Milestone]: "Milestones",
    [EventTypeCategory.Reflection]: "Reflection Lessons",
    [EventTypeCategory.Survey]: "Surveys",
    [EventTypeCategory.TeamCheck]: "Team Checks",
  }[category];
}

export {
  toSchedulePlan,
  toCalendarEvents,
  createScheduleElement,
  toEventUpdateData,
  toUpdatedScheduleElement,
  isAssignableContent,
  parsePublishDateTime,
  parseDueDateTime,
  toApiEvent,
  toApiSchedulePlanCreatePayload,
  elementsChanged,
  parseFullCalendarDueDate,
  parseFullCalendarPublishDate,
  parseFullCalendarDates,
  isMilestoneEvent,
  isAssignmentEvent,
  inDateTimeRange,
  scheduleElementToApiEvent,
  toScheduleElement,
  categoryLabel,
  Event,
  EventType,
  EventUpdateData,
  EventCreateData,
  Assignment,
  EventTypeCategory,
  CalendarEvent,
  APISchedulePlan,
  APIScheduleElement,
  SchedulePlan,
  ScheduleError,
  ErrorLevel,
  Milestone,
  ISODateString,
  ScheduleElement,
  SurveyType,
  TANDEM_DATE_SHORT,
  TANDEM_TIME_WITH_SECONDS,
  clampedDateTime,
};
